import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faExclamationCircle, faEdit, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import Link from React Router
import EditProfileForm from './EditProfileForm'; // Import the EditProfileForm component
import Siblings from './Siblings';
import PaymentsOverlay from './PaymentsOverlay';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StudentInfoBox = ({ studentId, isActive, setIsActive }) => {
  const [studentInfo, setStudentInfo] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // State to manage edit mode
  const [isSiblingsOpen, setIsSiblingsOpen] = useState(false); // State to manage sibling overlay
  const [isPaymentsOpen, setIsPaymentsOpen] = useState(false); // State to manage payments overlay
  const [siblings, setSiblings] = useState([]);

  // Fetch student information
  const fetchStudentInfo = async () => {
    try {
      const response = await axios.get(`http://mentorsattendance.site:5001/api/get-student-info/${studentId}`);
      console.log(response.data.studentInfo);
      setStudentInfo(response.data.studentInfo);
      setIsActive(response.data.studentInfo.active); // Set active status
    } catch (error) {
      console.error('Error fetching student information:', error);
    }
  };

  useEffect(() => {
    if (studentId) {
      fetchStudentInfo();
    }
  }, [studentId]);

  const fetchSiblings = async () => {
    try {
      const response = await axios.get(`http://mentorsattendance.site:5001/api/get-siblings/${studentId}`);
      console.log(response.data.siblings);
      setSiblings(response.data.siblings);
    } catch (error) {
      console.error('Error fetching siblings:', error);
    }
  };

  useEffect(() => {
    if (studentId) {
      fetchSiblings();
    }
  }, [studentId]);

  const handleEditProfile = () => {
    // Set edit mode to true when "Edit Profile" is clicked
    setIsEditing(true);
  };

  const handleCloseEditProfile = () => {
    // Set edit mode to false when closing the form
    setIsEditing(false);
    fetchStudentInfo();
  };

  const handleSiblings = () => {
    // Set sibling overlay to open when "Siblings" button is clicked
    setIsSiblingsOpen(true);
  };

  const handleCloseSiblings = () => {
    // Close sibling overlay
    setIsSiblingsOpen(false);
  };

  const handlePayments = () => {
    console.log("Opening Payments overlay...");
    setIsPaymentsOpen(true);
  };

  const handleClosePayments = () => {
    console.log("Closing Payments overlay...");
    fetchStudentInfo();
    setIsPaymentsOpen(false);
  };

  const refreshBalance = () => {
    // Function to refresh balance, you can call the API endpoint or any other method to refresh the balance
    fetchSiblings(); // Assuming this function fetches the updated siblings data
  };

  if (!studentInfo) {
    return <div>Loading...</div>;
  }

  const handleActivateDeactivate = async () => {
    try {
      // Toggle the active status
      await axios.put(`http://mentorsattendance.site:5001/api/students/${studentId}/activate`, { active: !isActive });
      setIsActive(!isActive); // Update local state
      toast.success(`Student ${!isActive ? 'activated' : 'deactivated'}`);
    } catch (error) {
      toast.error('Error activating/deactivating student:', error);
      console.error('Error activating/deactivating student:', error);
    }
  };

  const sendHomeworkIncompleteEmail = async () => {
    try {
      // Send email notifying about homework incomplete
      await axios.post(`http://mentorsattendance.site:5001/api/send-homework-incomplete-email/${studentId}`);
      toast.success('Email sent notifying about homework incomplete.');
    } catch (error) {
      toast.error('Error sending homework incomplete email:', error);
      console.error('Error sending homework incomplete email:', error);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <div className="student-info-container">
      <ToastContainer position="top-center"/>
      <div className='student-info-wrapper'>
        <div className="student-info-box">
            <h1>{studentInfo.nickname}</h1>
            <span className="grade-badge">{studentInfo.grade}</span>
            <p className='student-info-address'>{studentInfo.address}</p>
            <p>
              <span class="student-info-label">Full Name:</span>
              <span class="student-info-value">{studentInfo.name}</span>
            </p>
            <p>
              <span class="student-info-label">Email:</span>
              <span class="student-info-value">
                {truncateText(studentInfo.email, 20)}
                <Link to={`/emails?recipient=${encodeURIComponent(studentInfo.email)}`}>
                  <FontAwesomeIcon icon={faEnvelope} className="email-icon" />
                </Link>
              </span>
            </p>
            <p>
              <span class="student-info-label">Contact (Parent):</span>
              <span class="student-info-value">
                {studentInfo.parent_phone}
                <a href={`tel:${studentInfo.parent_phone}`}>
                  <FontAwesomeIcon icon={faPhone} className="email-icon" />
                </a>
              </span>
            </p>
            <p>
              <span class="student-info-label">Contact (Student):</span>
              <span class="student-info-value">
                {studentInfo.student_phone}
                <a href={`tel:${studentInfo.student_phone}`}>
                  <FontAwesomeIcon icon={faPhone} className="phone-icon" />
                </a>
              </span>
            </p>
            <hr className="divider" />
            <div className='button-row'>
                <button className="payments-button" onClick={handlePayments}>
                  Payments
                  <span className="mini-badge">{`$${studentInfo.balance}`}</span>
                  </button>
                <button className="siblings-button" onClick={handleSiblings}>
                  Siblings
                  <span className="mini-badge">{siblings.length - 1}</span>
                  </button>
                <button className="payments-button" onClick={sendHomeworkIncompleteEmail}>
                  HW Incomplete
                  <FontAwesomeIcon icon={faExclamationCircle} className="icon" />
                </button>
            </div>
            <hr className="divider" />
            <div className='button-row'>
                <button className="activate-button" onClick={handleActivateDeactivate}>
                  {isActive ? 'Deactivate' : 'Activate'}
                  <FontAwesomeIcon icon={isActive ? faTimesCircle : faCheckCircle} className="icon" />
                </button>
                <button className="edit-button" onClick={handleEditProfile}>
                  Edit Profile
                  <FontAwesomeIcon icon={faEdit} className="icon" />
                </button>
            </div>
        </div>
        {isEditing && (
            <div className="edit-profile-overlay">
            <EditProfileForm initialValues={studentInfo} onClose={handleCloseEditProfile} />
            </div>
        )}
        {isSiblingsOpen && (
            <div className="sibling-overlay">
            <Siblings initialValues={studentInfo} siblings={siblings} onClose={handleCloseSiblings} refreshBalance={refreshBalance}/>
            <button className="close-siblings-button" onClick={handleCloseSiblings}>Close</button>
            </div>
        )}
        {isPaymentsOpen && ( // Render PaymentsOverlay component if isPaymentsOpen is true
          <div className="payments-overlay">
            <PaymentsOverlay 
              studentId={studentId}
              fetchStudentInfo={fetchStudentInfo}
              studentName={studentInfo.name}
              onClose={handleClosePayments}
            />
          </div>
        )}
      </div>  
    </div>
  );
};

export default StudentInfoBox;
