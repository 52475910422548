import React, { useState, useEffect } from 'react';
import ReactSwitch from 'react-switch'; // Import the ReactSwitch component
import { Link } from 'react-router-dom';

const AttendanceTable = ({ students, attendanceData, handleToggleAttendance, handleMarkAllPresent, markAllPresent, selectedDate }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [showPresentStudentsOnly, setShowPresentStudentsOnly] = useState(false);
  const [presentOnly,setPresentOnly] = useState([]);
  const [combinedStudentsAttendance,setCombinedStudentsAttendance] = useState([]);

  useEffect(() => {
    // Merge filtered attendanceData with students and sort the combined array
    const mergedAndSorted = [...students, ...attendanceData].sort((a, b) => {
      // Sort logic based on student_id or any other appropriate field
      const nicknameA = a.nickname.toLowerCase();
      const nicknameB = b.nickname.toLowerCase();
    
      // Sort based on nicknames
      if (nicknameA < nicknameB) {
        return -1;
      }
      if (nicknameA > nicknameB) {
        return 1;
      }
      return 0;
    });
      // Filter out duplicate records from attendanceData based on student_id
      const filteredAttendanceData = mergedAndSorted.filter((item, index, self) =>
      index === self.findIndex((obj) => obj.student_id === item.student_id)
    );
    // Update the state with the combined and sorted array
    setCombinedStudentsAttendance(filteredAttendanceData);
  }, [students, attendanceData]);

  // Check if attendance has been saved for the selected date
  console.log(attendanceData);
  console.log(combinedStudentsAttendance);

  const isAttendanceSaved = attendanceData.length > 0;
  let totalPresent = 0;
  let totalMissing = 0;
  let totalStudents = 0;

  if (isAttendanceSaved) {
    totalPresent = attendanceData.filter(record => record.status === 'P').length;
    totalMissing = attendanceData.length - totalPresent;
    totalStudents = attendanceData.length ;
  } else {
    totalPresent = students.filter(student => student.status==="P").length;
    totalMissing = students.length - totalPresent;
    totalStudents = totalPresent + totalMissing;
  }

  let attendanceRate = Math.round((totalPresent / totalStudents) * 100).toFixed(2) + "%";;

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };


  const getStudentAttendanceStatus = (id) => {
    const student = attendanceData?.find((item)=>item.student_id===id);
    return student?.status==="P" ? true : false;
  }


  const handleTogglePresent = () => {
    if (showPresentStudentsOnly) {
      setPresentOnly([]);
      setShowPresentStudentsOnly(false);
    } else {
      const result1 = students.filter((item) => item.status === "P");
      const result2 = attendanceData.filter((item) => item.status === "P");
      let final = result1.concat(result2);
      final = final.filter((obj, index, self) =>
        index === self.findIndex((o) => o.student_id === obj.student_id)
      );
      const sortedPresentStudents = final.sort((a, b) => {
        const nicknameA = a.nickname.toLowerCase();
        const nicknameB = b.nickname.toLowerCase();
        if (nicknameA < nicknameB) {
          return -1;
        }
        if (nicknameA > nicknameB) {
          return 1;
        }
        return 0;
      });
      setPresentOnly(sortedPresentStudents);
      setShowPresentStudentsOnly(true);
    }
  };
  

  return (
    <div className='info-table-container'>
      {/* Display attendance information */}
      <div className="class-info">
        <a className='display-class-info' href="#" onClick={toggleVisibility}>Click to display attendance Information ...</a>
        {isVisible && ( // Conditionally render class info based on visibility and data availability
          <div className='class-info-section'>
            <p className='class-info-values'>
              Date: <span className="badge"><span className="smaller-badge badge-text">{selectedDate}</span></span> | 
              Total Students: <span className="badge"><span className="smaller-badge badge-text">{totalStudents}</span></span> |
              Total Present: <span className="badge present-badge"><span className="smaller-badge badge-text">{totalPresent}</span></span> | 
              Total Missing: <span className="badge missing-badge"><span className="badge-text smaller-badge">{totalMissing}</span></span> | 
              Attendance Rate: <span className="badge attendance-badge"><span className="badge-text smaller-badge">{attendanceRate}</span></span> | 
              <span style={{ color: '#ff0066' }}> Moved students</span> 
            </p>
            <button className='print-attendance-button' onClick={() => window.print()}>Print »</button>
          </div>
        )}
      </div>
        <div className='toggles'>
          <div className='mark-all-section'>
            <h3 className="class-students-header">Mark All Present</h3>
            <ReactSwitch
              checked={markAllPresent}
              onChange={handleMarkAllPresent}
              height={24} // Adjust the height here
              width={48} // Adjust the width here
              borderColor="#000000" // Set the border color here
            />
          </div>
          <div className="present-toggle">
          <h3 className="class-students-header">Show present students only</h3>
            <ReactSwitch
              checked={showPresentStudentsOnly}
              onChange={handleTogglePresent}
              height={24} // Adjust the height here
              width={48} // Adjust the width here
              borderColor="#000000" // Set the border color here
            />
          </div>
        </div>
      <table className="custom-table attendance-t">
        <thead>
          <tr>
            <th>#</th>
            <th>Student Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {showPresentStudentsOnly ? 
          presentOnly
          .map((record, index) => {
            // Determine if the student has been moved
            const isMoved = record.hasOwnProperty('is_moved') && record.is_moved;
            // Define the style for moved students
            const movedStyle = isMoved ? { color: '#ff0066' } : {};
            return (
              <tr key={record.student_id}>
              <td>{index + 1}</td>
              <td>
                <Link 
                  style={movedStyle}
                  to={`/students/${encodeURIComponent(record.nickname)}`} className="student-link">
                  {record.nickname} 
                </Link>
              </td>
              <td> 
                {/* {JSON.stringify(record)} */}
                <ReactSwitch
                  checked={record.status==="P"}
                  onChange={() => handleToggleAttendance(record.student_id,false)}
                  height={24} // Adjust the height here
                  width={48} // Adjust the width here
                />
              </td>
            </tr>
            )
          })
          :
          <>
            {combinedStudentsAttendance.map((student, index) => {
              // Determine if the student has been moved
              const isMoved = student.hasOwnProperty('is_moved') && student.is_moved;
              // Define the style for moved students
              const movedStyle = isMoved ? { color: '#ff0066' } : {};
              return (
                <tr key={student.student_id} style={movedStyle}>
                  <td>{index + 1}</td>
                  <td>
                    <Link style={movedStyle} to={`/students/${encodeURIComponent(student.nickname)}`} className="student-link">
                      {student.nickname} 
                    </Link>
                  </td>
                  <td>
                    <ReactSwitch
                      checked={student.status ? student.status==="P" ? true: false: getStudentAttendanceStatus(student.student_id)}
                      onChange={() => handleToggleAttendance(student.student_id,true)}
                      height={24} // Adjust the height here
                      width={48} // Adjust the width here
                    />
                  </td>
                </tr>
              );
            })}
          </>
          }  
        </tbody>
      </table>
    </div>
  );
}

export default AttendanceTable;