import axios from 'axios';
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SubjectRegistration = () => {
  const [formData, setFormData] = useState({
    subjectName: '',
    subjectCode: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      await axios.post('http://mentorsattendance.site:5001/api/register-subject', formData);
      toast.success('Subject registered successfully');
      setFormData({
        subjectName: '',
        subjectCode: ''
      });
    } catch (error) {
      console.error('Error registering subject:', error);
      toast.error('Failed to register subject');
    }
  };

  return (
    <div className='subject-registration-area'>
      <ToastContainer position="top-center"/>
      <h2 className='registration-header'>Subject Registration</h2>
      <form onSubmit={handleSubmit} className="subject-registration-form-group">
        <div className="subject-registration-form-item">
          <label className="subject-registration-form-label">Subject Name</label>
          <input type="text" name="subjectName" value={formData.subjectName} onChange={handleChange} required placeholder='Math' className="subject-registration-form-input" />
        </div>
        <div className="subject-registration-form-item">
          <label className="subject-registration-form-label">Subject Code</label>
          <input type="text" name="subjectCode" value={formData.subjectCode} onChange={handleChange} required placeholder='MA' className="subject-registration-form-input" />
        </div>
        <button type="submit" className="register-subject-button">Register Subject</button>
      </form>
    </div>
  );
};

export default SubjectRegistration;

