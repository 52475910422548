import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import Login from './components/Login';
import Main from './components/Main';
import RegistrationPage from './components/RegistrationPage';
import MainNavbar from './components/MainNavbar';
import ClassList from './components/ClassList';
import StudentPage from './components/StudentPage';
import PaymentsPage from './components/PaymentsPage';
import ActiveStudents from './components/ActiveStudents';
import EmailForm from './components/EmailForm';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [userRole, setUserRole] = useState(localStorage.getItem('userRole') || null);

  useEffect(() => {
    // Check if user is logged in
    const checkLoggedIn = async () => {
      try {
        const response = await axios.get('http://mentorsattendance.site:5001/api/checkLoginStatus');
        if (response.data.isLoggedIn) {
          setIsLoggedIn(true);
          setUserRole(response.data.userRole);
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('userRole', response.data.userRole);
        }
      } catch (error) {
        console.error('Error checking login status:', error);
      }
    };

    checkLoggedIn();
  }, []);

  const handleLogout = async () => {
    try {
      await axios.get('http://mentorsattendance.site:5001/api/logout');
      setIsLoggedIn(false);
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('userRole');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <Router>
      <div>
        {/* Render MainNavbar only if user is logged in */}
        {isLoggedIn && <MainNavbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} userRole={userRole}/>}

        <Routes>
          {/* Allow access to Login page regardless of login status */}
          <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} />} />

          {/* Render other routes only if user is logged in */}
          {isLoggedIn ? (
            <>
              <Route path="/main" element={<Main />} />
              <Route path="/registration" element={<RegistrationPage />} />
              <Route path="/list" element={<ClassList />} />
              <Route path="/students/:nickname" element={<StudentPage />} />
              {userRole === 'admin' && <Route path="/payments" element={<PaymentsPage />} />}
              <Route path="/active-students" element={<ActiveStudents />} />
              <Route path="/emails" element={<EmailForm />} />
            </>
          ) : (
            // Redirect to Login page if user is not logged in
            <Route path="*" element={<Navigate to="/" />} />
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;




