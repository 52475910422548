import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from './Header';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const PaymentsPage = () => {
  const [activeTab, setActiveTab] = useState('due');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="payments-page">
      <div>
        <Header></Header>
      </div>
      <div className="tabs">
        <button className={activeTab === 'due' ? 'active' : ''} onClick={() => handleTabChange('due')}>
          Payments Due
        </button>
        <button className={activeTab === 'made' ? 'active' : ''} onClick={() => handleTabChange('made')}>
          Payments Made
        </button>
      </div>
      {activeTab === 'due' ? <PaymentsDue /> : <PaymentsMade />}
    </div>
  );
};

const PaymentsDue = () => {
    const [paymentsDue, setPaymentsDue] = useState([]);
    const [totalDue, setTotalDue] = useState(0.0);
    const [sendingStatus, setSendingStatus] = useState(''); // New state for status message
    const [lastReminderSent, setLastReminderSent] = useState(''); // New state for last reminder sent
  
    useEffect(() => {
      // Fetch payments due data from backend using Axios
      axios.get('http://mentorsattendance.site:5001/api/get-payments-due')
        .then(response => {
          // Log the fetched data
          console.log(response.data);
          // Update state with the fetched data
          setPaymentsDue(response.data.missedPayments);
          const total = response.data.missedPayments.reduce((acc, payment) => acc + Number(payment.amountDue), 0);
          setTotalDue(total.toFixed(2));
        })
        .catch(error => console.error('Error fetching payments due:', error));
        
      // Fetch last reminder sent timestamp
      axios.get('http://mentorsattendance.site:5001/api/get-last-reminder-sent')
      .then(response => {
        const lastSent = response.data.lastSent;
        if (lastSent) {
          const now = new Date();
          const lastSentDate = new Date(lastSent);
          const timeDiff = now - lastSentDate;
          const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
          setLastReminderSent(days > 0 ? `${days}d` : 'Today');
        } else {
          setLastReminderSent('N/A');
        }
      })
      .catch(error => console.error('Error fetching last reminder sent:', error));
    }, []);

    // In PaymentsDue component
    const sendPaymentReminders = () => {
      // Update UI to indicate that emails are being sent
      setSendingStatus('Sending emails...');
      
      // Send request to backend to trigger payment reminders
      axios.post('http://mentorsattendance.site:5001/api/send-payment-reminders')
        .then(response => {
          console.log(response.data.message); // Log success message
          // Optionally, you can update the UI to indicate success
          setSendingStatus('All emails sent.');
          // Update last reminder sent status
          const now = new Date();
          setLastReminderSent('Today');
          toast.success("Reminders sent out");
        })
        .catch(error => {
          console.error('Error sending payment reminders:', error);
          setSendingStatus('An error occurred while sending emails.');
          toast.error("Error occured while sending reminders. Try again later.");
        });
    };
  
    return (
      <div className="payments-due">
      <ToastContainer position="top-center"/>
      <h2 className='class-list-header'>Payments Due</h2>
      <div className='print-section'>
        <button className='print-attendance-button' onClick={() => window.print()}>Print »</button>
      </div>
      {/* Render payments due data */}
      {paymentsDue.length > 0 ? (
        <div className='info-table-container'>
          <table className='custom-table'>
            <thead>
              <tr>
                <th>#</th>
                <th>Student Name</th>
                <th>Payment Expected</th>
                <th>Last Payment</th>
              </tr>
            </thead>
            <tbody>
              {paymentsDue.map((payment, index) => (
                <tr key={payment.studentId}>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={`/students/${encodeURIComponent(payment.studentName)}`} className="student-link">
                      {payment.studentName} 
                    </Link>
                  </td>
                  <td>{`$${payment.amountDue}`}</td>
                  <td>{payment.lastPaymentDate}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="1"></td>
                <td><strong>Total:</strong></td>
                <td>{`$${totalDue}`}</td>
              </tr>
            </tfoot>
          </table>
          <div className='button-status-container'>
            <button className='send-reminder-button' onClick={sendPaymentReminders}>Send Reminders
              <div className="last-reminder">
                Last sent: <span className="badge custom-badge"><span className="smaller-badge badge-text custom-badge-text">{lastReminderSent}</span></span>
              </div>
            </button>
            {sendingStatus && <div className="status-message">{sendingStatus}</div>} {/* Status message display */}
          </div>
        </div>
      ) : (
        <p>No payments due.</p>
      )}
    </div>
    );
  };
  
const PaymentsMade = () => {
    const currentDate = new Date();
    const defaultMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const defaultYear = currentDate.getFullYear().toString();
    const [paymentsMade, setPaymentsMade] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
    const [selectedYear, setSelectedYear] = useState(defaultYear);
    const [totalPaid, setTotalPaid] = useState(0.00)
  
    useEffect(() => {
      console.log(selectedMonth);
      console.log(selectedYear);
      // Fetch payments made data from backend using Axios
      if (selectedMonth && selectedYear) {
        axios.get(`http://mentorsattendance.site:5001/api/get-payments-made?month=${selectedMonth}&year=${selectedYear}`)
          .then(response => {
            // Log the fetched data
            console.log(response.data);
            // Update state with the fetched data
            setPaymentsMade(response.data.paymentsMade);
            const total = response.data.paymentsMade.reduce((acc, payment) => acc + Number(payment.amountReceived), 0);
            setTotalPaid(total.toFixed(2));
            console.log(totalPaid);
          })
          .catch(error => console.error('Error fetching payments made:', error));
      }
    }, [selectedMonth, selectedYear]);

    // Function to populate options for month and year select dropdowns
  const populateMonthOptions = () => {
    const months = [
      { value: '01', label: 'January' },
      { value: '02', label: 'February' },
      { value: '03', label: 'March' },
      { value: '04', label: 'April' },
      { value: '05', label: 'May' },
      { value: '06', label: 'June' },
      { value: '07', label: 'July' },
      { value: '08', label: 'August' },
      { value: '09', label: 'September' },
      { value: '10', label: 'October' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' },
    ];
    return months.map(month => (
      <option key={month.value} value={month.value}>{month.label}</option>
    ));
  };

  const populateYearOptions = () => {
    // Replace this with your logic to get the year range
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2022; year--) {
      years.push(year);
    }
    return years.map(year => (
      <option key={year} value={year}>{year}</option>
    ));
  };
  
    return (
      <div className="payments-made">
        <h2 className='class-list-header'>Payments Made</h2>
        <div className='print-and-dropdowns'>
          {/* Dropdown for month and year selection */}
          <div className="dropdown selections">
            <select className='registration-select' value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
              <option value="">Select Month</option>
              {populateMonthOptions()}
            </select>
            <select className='registration-select' value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
              <option value="">Select Year</option>
              {populateYearOptions()}
            </select>
          </div>
          <button className='print-attendance-button' onClick={() => window.print()}>Print »</button>
        </div>
        {/* Table to display payments made */}
        <div className='info-table-container'>
          <table className='custom-table'>
            <thead>
              <tr>
                <th>#</th>
                <th>Student Name</th>
                <th>Payment Amount</th>
                <th>Payment Date</th>
              </tr>
            </thead>
            <tbody>
              {paymentsMade.map((payment, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={`/students/${encodeURIComponent(payment.studentNickname)}`} className="student-link">
                      {payment.studentNickname} 
                    </Link>
                  </td>
                  <td>{`$${payment.amountReceived}`}</td>
                  <td>{payment.paymentDate.substring(0,10)}</td>
                </tr>
              ))}
            </tbody>
            {/* Table footer with total amount */}
            <tfoot>
              <tr>
                  <td colSpan="1"></td>
                  <td><strong>Total:</strong></td>
                  <td>{`$${totalPaid}`}</td>
              </tr>
            </tfoot>
          </table>
          </div>
      </div>
    );
  };

export default PaymentsPage;
