import React from 'react';
import NewStudentRegistration from './NewStudentRegistration';
import SubjectRegistration from './SubjectRegistration';
import NewClassRegistration from './NewClassRegistration';

const RegistrationPage = () => {
  return (
    <div className='registration-components-area'>
      <NewStudentRegistration />
      <SubjectRegistration />
      <NewClassRegistration />
    </div>
  );
};

export default RegistrationPage;
