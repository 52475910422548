import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ClassDropdown = ({ selectedSubject, setSelectedSubject, onSelectClass, onSelectDayOfWeek, onSelectTime }) => {
  const [subjects, setSubjects] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(null);
  const [times, setTimes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSubjects = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get('http://mentorsattendance.site:5001/api/subjects');
        console.log("Subjects response:", response.data); // Add this log statement
        setSubjects(response.data);
      } catch (error) {
        console.error('Error fetching subjects:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubjects();
  }, []);

  useEffect(() => {
    console.log("Selected subject:", selectedSubject);
    if (selectedSubject) {
      const fetchDaysOfWeek = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(`http://mentorsattendance.site:5001/api/daysOfWeek?subject=${selectedSubject}`);
          console.log("Days of the week response:", response.data); // Add this log statement
          setDaysOfWeek(response.data);
        } catch (error) {
          console.error('Error fetching days of week:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchDaysOfWeek();
    }
  }, [selectedSubject]);

  useEffect(() => {
    console.log("Selected day of week:", selectedDayOfWeek);
    if (selectedSubject && selectedDayOfWeek) {
      const fetchTimes = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(`http://mentorsattendance.site:5001/api/times?subject=${selectedSubject}&dayOfWeek=${selectedDayOfWeek}`);
          console.log("Times:", response.data); // Add this log statement
          setTimes(response.data);
        } catch (error) {
          console.error('Error fetching times:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchTimes();
    }
  }, [selectedSubject, selectedDayOfWeek]);

  const handleSelectSubject = (subject) => {
    if (subject) {
      setSelectedSubject(subject);
      setSelectedDayOfWeek(null); // Reset day of week selection when subject changes
    }
  };

  const handleSelectDayOfWeek = (dayOfWeek) => {
    setSelectedDayOfWeek(dayOfWeek);
    onSelectDayOfWeek(dayOfWeek); // Pass selected day of week to parent component
  };

  const handleSelectTime = (time) => {
    onSelectTime(time); // Pass selected time to parent component
    console.log("Selected time:", time);
  };

  return (
    <div>
      <select className='registration-select' disabled={isLoading} onChange={(e) => handleSelectSubject(e.target.value)}>
        <option value="">Select Subject...</option>
        {subjects.map((subject, index) => (
          <option key={index} value={subject}>
            {subject}
          </option>
        ))}
      </select>
      {selectedSubject && (
        <select className='registration-select' disabled={isLoading} onChange={(e) => handleSelectDayOfWeek(e.target.value)}>
          <option value="">Select Day of Week...</option>
          {daysOfWeek.map((day) => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </select>
      )}
      {selectedDayOfWeek && (
        <select className='registration-select' disabled={isLoading} onChange={(e) => handleSelectTime(e.target.value)}>
          <option value="">Select Time...</option>
          {times.map((time, index) => (
            <option key={index} value={`${time.start_time}-${time.end_time}`}>
              {`${time.start_time}-${time.end_time}`}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default ClassDropdown;



