import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from './Header';

const ActiveStudents = () => {
  // need row #, student id, nickname, grade, email and date registered
  const [activeStudents, setActiveStudents] = useState([]);

  useEffect(() => {
    const fetchActiveStudents = async () => {
      try {
        // Fetch all active students
        const response = await axios.get('http://mentorsattendance.site:5001/api/active-students');
        setActiveStudents(response.data);
        console.log(response.data);
      } catch (err) {
        console.log('Error fetching active students:', err);
      }
    };

    // Call the fetchActiveStudents function immediately
    fetchActiveStudents();
  }, []); // Empty dependency array to ensure the effect runs only once

  return( 
    <div className='active-students'>
      <div><Header></Header></div>
      <div className='info-table-container'>
          <h1 className='class-list-header'>Active Students</h1>
          <div className='print-total-section'>
            <p>Total Students: {activeStudents.length}</p>
            <button className='print-attendance-button' onClick={() => window.print()}>Print »</button>
          </div>
          <table className="custom-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Grade</th>
                  <th>Email</th>
                  <th>Date Registered</th>
                </tr>
              </thead>
              <tbody>
                {activeStudents.map((student, index) => (
                  <tr key={student.student_id}>
                    <td>{index + 1}</td>
                    <td>
                      <Link to={`/students/${encodeURIComponent(student.nickname)}`} className="student-link">
                        {student.nickname} 
                      </Link>
                    </td>
                    <td>{student.grade}</td>
                    <td>{student.email}</td>
                    <td>{student.registration_date.substring(0,10)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
      </div>
    </div>
  );
};

export default ActiveStudents;
