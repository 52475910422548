import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AttendanceDropDown from './AttendanceDropdown'
import AttendanceTable from './AttendanceTable';
import MoveSection from './MoveSection';
import ReactSwitch from 'react-switch';
import Header from './Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Main = () => {
  // Define state variables
  const [selectedDate, setSelectedDate] = useState('');
  const [classTimes, setClassTimes] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedWeekday, setSelectedWeekday] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [studentName, setStudentName] = useState('');
  const [subject, setSubject] = useState('');
  const [subjects, setSubjects] = useState([]); // State variable for subjects
  const [searchResults, setSearchResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [markAllPresent, setMarkAllPresent] = useState(false);
  const [openButtonClicked, setOpenButtonClicked] = useState(false); 

  useEffect(() => {
    // Check if all required variables are set
    if (selectedDate && selectedWeekday && startTime && endTime && openButtonClicked) {
      // Fetch previously saved attendance when component mounts
      fetchAttendanceData();
      fetchStudents();
    }
  }, [selectedDate, selectedWeekday, startTime, endTime, openButtonClicked]);

  const fetchAttendanceData = async () => {
    console.log("Changing");
    try {
      // Make GET request to fetch attendance data for selected date and time range
      const response = await axios.get(`http://mentorsattendance.site:5001/api/attendance`, {
        params: {
          selectedDate,
          selectedWeekday,
          startTime,
          endTime
        }
      });
      if(response.data.attendance){
        let totalP = response.data?.attendance?.filter((item)=>item.status==="P").length;
        if(totalP===response.data.attendance.length && response.data.attendance.length !==0){
          setMarkAllPresent(true);
        } else {
          setMarkAllPresent(false);
        }
      }
      // Update state with fetched attendance data
      setAttendanceData(response.data.attendance);
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    }
  };

  // Fetch class times based on the selected date and day of the week
  const fetchClassTimes = async (dayOfWeek) => {
    try {
      const response = await axios.get(`http://mentorsattendance.site:5001/api/class-times/${dayOfWeek}`);
      setClassTimes(response.data.classTimes); // Set class times in the state
    } catch (error) {
      console.error('Error fetching class times:', error);
    }
  };

  // Reset class times when the selected date changes
  useEffect(() => {
    if (selectedDate) {
      const date = new Date(selectedDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const localDate = new Date(utcDate.getTime() + (new Date().getTimezoneOffset() * 60000));
      const dayOfWeek = localDate.toLocaleDateString('en-US', { weekday: 'long' });
      setSelectedWeekday(dayOfWeek);
      console.log('Selected weekday:', dayOfWeek); // Add this log statement
      setClassTimes([]); // Reset class times
      fetchClassTimes(dayOfWeek); // Fetch class times for the new date
    }
  }, [selectedDate]);

  useEffect(() => {
    setStartTime('');
    setEndTime('');
    setOpenButtonClicked(false);
  }, [selectedDate]);

  // Fetch subjects when the component mounts
  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await axios.get('http://mentorsattendance.site:5001/api/subjects');
        console.log(response.data);
        setSubjects(response.data); // Set subjects in the state
      } catch (error) {
        console.error('Error fetching subjects:', error);
      }
    };

    fetchSubjects();
  }, []);

  // Function to generate dates for the current month within a range of 10 days before and after today
  const generateDatesForCurrentMonth = () => {
    const currentDate = new Date();
    const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const tenDaysBefore = new Date(today);
    tenDaysBefore.setDate(tenDaysBefore.getDate() - 10);
    const tenDaysAfter = new Date(today);
    tenDaysAfter.setDate(tenDaysAfter.getDate() + 10);
    const dates = [];
    for (let date = new Date(tenDaysBefore); date <= tenDaysAfter; date.setDate(date.getDate() + 1)) {
      const formattedDate = date.toISOString().slice(0, 10);
      const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' });
      dates.push({ date: formattedDate, dayOfWeek });
    }


    return dates;
  };

  // Render options for dates in the current month within a range of 10 days before and after today
  const renderDateOptions = () => {
    const dates = generateDatesForCurrentMonth();
    return dates.map((dateInfo) => (
      <option key={dateInfo.date} value={dateInfo.date}>
        {`${dateInfo.dayOfWeek}, ${dateInfo.date}`}
      </option>
    ));
  };

  // Fetch students based on selected date and class
  const fetchStudents = async () => {
    try {
      const response = await axios.get(`http://mentorsattendance.site:5001/api/students/${selectedWeekday}/${startTime}/${endTime}`);
      console.log('Response from fetchStudents:', response.data); // Log the response data
      if (Array.isArray(response.data.students)) {
        setStudents(response.data.students); // Set students array from response data
      } else {
        setStudents([]);
        console.error('Students data is not an array:', response.data.students);
      }
      } catch (error) {
        setStudents([]);
        console.error('Error fetching students:', error);
      }
  };

  // Render options for class times
  const renderClassTimeOptions = () => {
    // Create a set to store unique class times
    const uniqueClassTimes = new Set();

    // Filter out duplicate class times
    classTimes.forEach((item) => {
      uniqueClassTimes.add(`${item.start_time} - ${item.end_time}`);
    });

    // Convert set to array and map over unique class times to render options
    return Array.from(uniqueClassTimes).map((classTime) => (
      <option key={classTime} value={classTime}>{classTime}</option>
    ));
  };

    // Fetch classId for student who will be moved
  const fetchClassId = async () => {
    try {
      const response = await axios.get(`http://mentorsattendance.site:5001/api/classId`, {
        params: {
          subject: subject,
          dayOfWeek: selectedWeekday,
          startTime: startTime,
          endTime: endTime
        }
      });
      return response.data.classId;
    } catch (error) {
      console.error('Error fetching classId:', error);
      return null;
    }
  };

  // Move student to the current table
  const moveStudent = async () => {
    // Fetch classId
    const classId = await fetchClassId();
    if (!classId) {
      toast.error('Failed to fetch classId');
      return;
    }

    // Fetch student ID from the backend
    try {
      const response = await axios.get(`http://mentorsattendance.site:5001/api/student-id/${studentName}`);
      const studentId = response.data.studentId;

      if (!studentId) {
        toast.error('Failed to fetch studentId');
        return;
      }

      // Add student to the current table without adding them to the class list
      const newStudent = {
        student_id: studentId, // Use the fetched student ID
        nickname: studentName, // Use the provided student name
        status: 'P', // Default status to Present
        class_id: classId, // Use the fetched classId
        is_moved: true
      };

      console.log(newStudent);

      const insertionIndex = students.findIndex(student => student.nickname.localeCompare(studentName) > 0);
      // If the insertion index is -1, it means the new student comes after all existing students alphabetically
      if (insertionIndex === -1){
        setStudents([...students, newStudent]);
      } else {
      // Insert the new student into the array at the correct position
      setStudents([
        ...students.slice(0, insertionIndex),
        newStudent,
        ...students.slice(insertionIndex)
      ]);
      }
      setAttendanceData((prev)=>[...prev,newStudent]);
      setStudentName(''); // Clear student name input
      setSubject(''); // Clear subject input
    } catch (error) {
      console.error('Error fetching student ID:', error);
      toast.error('Failed to fetch student ID');
    }
  };

  // Handle toggle attendance
  const handleToggleAttendance = async (id,fromStudent=false) => {
    // Create a copy of the students array
    let updatedAttendance = attendanceData.find((item)=>item.student_id===id);
    if(fromStudent && !updatedAttendance){
      updatedAttendance = students.find(item=>item.student_id===id);
    }
    // Update the present status of the student at the specified index
    updatedAttendance = {
      ...updatedAttendance,
      status: updatedAttendance.status==="P" ? "A" : "P"
    };
    console.log(updatedAttendance);
   
    const updatedStudents = students.map((item)=>{
      if(item.student_id===id){
        return {
          ...item,
          ...updatedAttendance
        }
      } else {
        return item;
      }
    });
    const updatedAttendanceData = attendanceData.map((item)=>{
      if(item.student_id===id){
        return {
          ...item,
          ...updatedAttendance
        }
      } else {
        return item;
      }
    });
    setStudents(updatedStudents);
    setAttendanceData(updatedAttendanceData);
    if(markAllPresent && updatedAttendance.status==="A"){
      setMarkAllPresent(false);
    }
  };

 // Handle marking all students as present
const handleMarkAllPresent = async () => {
  // Toggle the state of the toggle button first
  console.log("Before toggling, markAllPresent:", markAllPresent);
  setMarkAllPresent(!markAllPresent);
  console.log("After toggling, markAllPresent:", !markAllPresent);

  // Determine the status to set for all students
  const statusToSet = !markAllPresent ? "P" :"A"; // Let's set it directly to the boolean value
  console.log("Status to set for all students:", statusToSet);

  // Create an array containing all students with the updated status
  const updatedStudents = students.map(student => {
    console.log(student);
    console.log("Type of status:", typeof student.status); // Log the type of status
    console.log("statusToSet: ",statusToSet);
    return {
      ...student,
      status: statusToSet
    };
  });

  const newAttendance = attendanceData.map( (item) => {
    return({...item,status:statusToSet}) 
  });
  console.log(newAttendance);
  setAttendanceData(newAttendance);


  console.log("updatedStatudents: ",updatedStudents);
  // Update the state with the modified students array
  setStudents(updatedStudents);
  // saveAttendance(updatedStudents);
};

  const getModifiedDateWithData = (arr) => {
    const date = new Date(selectedDate);
    // Convert the modified date back to ISO string format
    const modifiedDate = date.toISOString().slice(0, 10);
    console.log('Selected date:', modifiedDate);  
    const finalData = arr.map(student => {
    let data = {
      studentId: student.student_id,
      classId: student.class_id,
      status: student.status, // Map to 'P' for present and 'A' for absent
      attendanceDate: modifiedDate,
      is_moved:student.is_moved ? student.is_moved:false
    };
    if(!data.status){
      let status = attendanceData.find(item=>item.student_id===data.studentId)?.status
      data.status =  status ? status : "A"; 
    }
      return data;
    });
    return finalData;
  }

  // Save attendance
  const saveAttendance = async () => {
    try {
      const finalStudentsData = getModifiedDateWithData(students);
      const finalAttendance = getModifiedDateWithData(attendanceData);
      if(finalStudentsData.length > 0){
        await axios.post('http://mentorsattendance.site:5001/api/save-attendance', finalStudentsData);
      }
      if(finalAttendance.length > 0){
        await axios.post('http://mentorsattendance.site:5001/api/save-attendance', finalAttendance);
      } 
      toast.success('Attendance saved successfully');
      fetchAttendanceData();
    } catch (error) {
      console.error('Error saving attendance:', error);
      toast.error('Failed to save attendance');
    }
  };

  // Handle input change in the autocomplete field
  const handleInputChange = (e) => {
    const value = e.target.value;
    setStudentName(value); // Update the studentName state with the input value

    // Filter student list based on input value if it's not empty
    if (value.trim() !== "") {
      setIsLoading(true);
      axios.get(`http://mentorsattendance.site:5001/api/active-students`)
        .then(response => {
          const allStudents = response.data;
          const filtered = allStudents.filter(
            (student) =>
              student.nickname.toLowerCase().includes(value.toLowerCase()) &&
              !students.some(s => s.nickname === student.nickname)
          );
          setSearchResults(filtered); // Update the searchResults state with the filtered list
          setDropdownOpen(true); // Open the dropdown
        })
        .catch(error => {
          console.error('Error fetching students:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setSearchResults([]); // Clear the searchResults state if input is empty
      setDropdownOpen(false); // Close the dropdown
    }
  };


  // Handle suggestion click in the autocomplete dropdown
  const handleSuggestionClick = (name) => {
    setStudentName(name);
    setSearchInput(name);
    setDropdownOpen(false);
  };

  return (
  <div className="attendance-container">
  <ToastContainer position="top-center"/>
  <div>
    <Header></Header>
  </div>
  <h1 className='attendance-header'>Attendance</h1>
  <div className="attendance-section">
    <div className="dropdown-container">
      <AttendanceDropDown
        selectedDate={selectedDate}
        renderDateOptions={renderDateOptions}
        renderClassTimeOptions={renderClassTimeOptions}
        setSelectedDate={setSelectedDate}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        fetchStudents={fetchStudents}
        setOpenButtonClicked={setOpenButtonClicked}
      /> 
    </div>
    {openButtonClicked && ( // Conditionally render the AttendanceTable component only if the open button is clicked
      <div className='attendance-output'>
        <div className='table-container'>
          <AttendanceTable
            students={students}
            handleToggleAttendance={handleToggleAttendance}
            attendanceData={attendanceData}
            handleMarkAllPresent={handleMarkAllPresent}
            markAllPresent={markAllPresent}
            selectedDate={selectedDate}
          />
        </div>
        <div className='move-section-container'>
            <MoveSection
              studentName={studentName}
              subject={subject}
              subjects={subjects}
              setStudentName={setStudentName}
              setSubject={setSubject}
              moveStudent={moveStudent}
              handleInputChange={handleInputChange}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              isLoading={isLoading}
              dropdownOpen={dropdownOpen}
              setDropdownOpen={setDropdownOpen}
              handleSuggestionClick={handleSuggestionClick}
            />
            <button className='save-attendance-button' onClick={()=>saveAttendance()}>Save Attendance</button>
        </div>
      </div>
    )}
  </div>
</div>
  );
}

export default Main;