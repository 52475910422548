import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Siblings = ({ initialValues, siblings, onClose, refreshBalance }) => {
  const [paymentAmounts, setPaymentAmounts] = useState(Array(siblings.length).fill(''));
  const [balanceUpdates, setBalanceUpdates] = useState(Array(siblings.length).fill(''));
  const [newSiblingInfo, setNewSiblingInfo] = useState({
    name: '',
    nickname: '',
    registrationDate: '',
    monthlyPayment: '',
    grade: '',
  });

  const handlePaymentAmountChange = (event, index) => {
    const newPaymentAmounts = [...paymentAmounts];
    newPaymentAmounts[index] = event.target.value;
    setPaymentAmounts(newPaymentAmounts);
  };

  const handleNewSiblingInfoChange = (event) => {
    const { name, value } = event.target;
    setNewSiblingInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleBalanceUpdateChange = (event, index) => {
    const newBalanceUpdates = [...balanceUpdates];
    newBalanceUpdates[index] = event.target.value;
    setBalanceUpdates(newBalanceUpdates);
  };

  const handleSubmitNewSibling = (event) => {
    event.preventDefault();
    // Handle submission of new sibling form
    console.log('New sibling info:', newSiblingInfo);
    // Send request to register sibling
    axios.post('http://mentorsattendance.site:5001/api/register-student', {
      // Pass sibling information to the endpoint
      ...newSiblingInfo,
      email: initialValues.email,
      parentPhone: initialValues.parent_phone,
      studentPhone: initialValues.student_phone,
      address: initialValues.address,
    })
    .then(response => {
      // Handle success
      toast.success('Sibling successfully registered');
      console.log(response.data.message);
      // Optionally, you can refresh the siblings data after registering a new sibling
      refreshBalance();
      setNewSiblingInfo({
        name: '',
        nickname: '',
        registrationDate: '',
        monthlyPayment: '',
        grade: '',
      });
    })
    .catch(error => {
      // Handle error
      toast.error('Error registering sibling:', error);
      console.error('Error registering sibling:', error);
    });
  };

  const handleProcessPayment = () => {
    console.log("Sibling IDs:", siblings.map(sibling => sibling.studentId));
    // Filter payment amounts and sibling IDs to include only those with payment amounts
    const paidSiblingAmounts = paymentAmounts.filter(amount => amount.trim() !== '');
    const paidSiblingIds = siblings.filter((_, index) => paymentAmounts[index].trim() !== '').map(sibling => sibling.studentId);

    // If no siblings have payment amounts specified, return early
    if (paidSiblingAmounts.length === 0) {
      toast.error('Please enter payment amount for at least one sibling.');
      return;
    }
    // Send a request to the backend to process the payment
    axios.post('http://mentorsattendance.site:5001/api/process-siblings-payment', {
      paymentAmounts: paidSiblingAmounts,
      siblingIds: paidSiblingIds
    })
    .then(response => {
      // Handle success
      toast.success('Siblings payment processed successfully')
      console.log(response.data.message);
      // Optionally, you can refresh the siblings data after processing payment
      refreshBalance();
      setPaymentAmounts(Array(siblings.length).fill(''));
    })
    .catch(error => {
      // Handle error
      console.error('Error processing payment:', error);
    });
  };

  const handleSetBalances = () => {
    // Filter balance updates and sibling IDs to include only those with updates
    const updatedBalances = balanceUpdates.filter(update => update.trim() !== '');
    const updatedSiblingIds = siblings.filter((_, index) => balanceUpdates[index].trim() !== '').map(sibling => sibling.studentId);

    // If no balance updates are specified, return early
    if (updatedBalances.length === 0) {
      toast.error('Please enter a new balance for at least one sibling.');
      return;
    }

    axios.post('http://mentorsattendance.site:5001/api/update-siblings-balances', {
      balanceAmounts: updatedBalances, // Pass the balance updates array
      siblingIds: updatedSiblingIds // Pass the sibling IDs
    })
    .then(response => {
      // Handle success
      toast.success('Sibling balances updated successfully');
      console.log(response.data.message);
      // Optionally, you can refresh the siblings data after updating balances
      refreshBalance();
      setBalanceUpdates(Array(siblings.length).fill(''))
    })
    .catch(error => {
      // Handle error
      console.error('Error updating balances:', error);
    });
  };

  // Generate options for grades 1 to 12 and University
  const gradeOptions = Array.from({ length: 12 }, (_, index) => (
    <option key={index + 1} value={`${index + 1}`}>
      {index + 1}
    </option>
  ));
  gradeOptions.push(<option key="UNI" value="UNI">University</option>);
  
  return (
    <div className="sibling-overlay">
      <div className="sibling-container">
        {/* Multiple Payments Section */}
        <div className="multiple-payments-section">
          <h2>Multiple Payments</h2>
          {siblings.map((sibling, index) => (
            <div className='sibling-payment' key={sibling.student_id}>
              <p>
                <Link to={`/students/${encodeURIComponent(sibling.nickname)}`} className="student-link" onClick={onClose}>
                  {sibling.nickname} 
                </Link>
                : ${sibling.amountDue}
              </p>
              <div className='payment-inputs'>
                <input
                  type="number"
                  value={paymentAmounts[index]}
                  onChange={(event) => handlePaymentAmountChange(event, index)}
                  placeholder="Enter payment amount"
                />
                <input
                  type="number"
                  value={balanceUpdates[index]}
                  onChange={(event) => handleBalanceUpdateChange(event, index)}
                  placeholder="Enter new balance"
                />
              </div>
            </div>
          ))}
          <button onClick={() => {handleSetBalances(); refreshBalance(); }}>Set Balances</button>
          <button onClick={() => {handleProcessPayment(); refreshBalance(); }}>Make Payment</button>
          <hr className="divider" />
        </div>
        {/* Register Sibling Section */}
        <div className="register-sibling-section">
          <h2>Register Sibling</h2>
          <form onSubmit={handleSubmitNewSibling}>
            <div>
              <label>Full Name:</label>
              <input type="text" name="name" value={newSiblingInfo.name} onChange={handleNewSiblingInfoChange} placeholder='Full Name' required/>
            </div>
            <div>
              <label>Nickname:</label>
              <input type="text" name="nickname" value={newSiblingInfo.nickname} onChange={handleNewSiblingInfoChange} placeholder='Nickname' required/>
            </div>
            <div>
              <label>Date of Registration:</label>
              <input type="date" name="registrationDate" value={newSiblingInfo.registrationDate} onChange={handleNewSiblingInfoChange} required/>
            </div>
            <div>
              <label>Monthly Payment:</label>
              <input type="number" name="monthlyPayment" value={newSiblingInfo.monthlyPayment} onChange={handleNewSiblingInfoChange} placeholder='400' required/>
            </div>
            <div>
              <label>Grade:</label>
              <select
                name="grade"
                value={newSiblingInfo.grade}
                onChange={handleNewSiblingInfoChange}
                className='registration-select'
                required
              >
                {gradeOptions}
              </select>
            </div>
            <button type="submit">Register</button>
          </form>
        </div>
        <button className="close-siblings-button" onClick={() => { onClose(); refreshBalance(); }}>X</button>
      </div>
      <ToastContainer position="top-center"/>
    </div>
  );
};

export default Siblings;


