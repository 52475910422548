import React from 'react';
import logoImage from "../img/logo.png"
import envelopeImage from "../img/envelope.png"
import globeImage from "../img/globe.png"
import phoneImage from "../img/phone.png"

const Header = () => {
  // Define the paths for your images
  const logoPath = logoImage;
  const globePath = globeImage;
  const envelopePath = envelopeImage;
  const phonePath = phoneImage;

  return (
    <div>
        <div className="header">
            <div className="logo">
                <img src={logoPath} alt="Banner" />
            </div>
            <div className="mentors-header-info">
                <h1 className="mentors-header">Mentor's Academy</h1>
                <p className="address">115 Lebovic Avenue, Unit: 3 & 4, Scarborough ON, M1L 4T7</p>
            </div>
        </div>
        <div className="mentors-info">
            <p>
            <img className="icons" src={globePath} alt="Globe Icon" />
            <span> www.mentorsacademy.ca</span>
            </p>
            <div className="icon-text-wrapper">
            <img className="icons" src={envelopePath} alt="Envelope Icon" />
            <p>contact@mentorsacademy.ca</p>
            </div>
            <p>
            <img className="icons" src={phonePath} alt="Phone Icon" />
            <span> 647-780-8811</span>
            </p>
        </div>
    </div>
  );
};

export default Header;
