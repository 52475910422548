import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const InfoTable = ({ selectedClass, refreshTable, setRefreshTable }) => {
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [classInfo, setClassInfo] = useState(null); // Initialize class info state
  const [isVisible, setIsVisible] = useState(false);
  const [classCode, setClassCode] = useState('');

  useEffect(() => {
    const fetchStudents = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`http://mentorsattendance.site:5001/api/class-students/${selectedClass}`);
        setStudents(response.data);
        setRefreshTable(false);
      } catch (error) {
        console.error("Error fetching students:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedClass && refreshTable) {
      fetchStudents();
    }
  }, [selectedClass, refreshTable, setRefreshTable]);

  // Function to fetch class information
  useEffect(() => {
    const fetchClassInfo = async () => {
      try {
        const response = await axios.get(`http://mentorsattendance.site:5001/api/class-info/${selectedClass}`);
        setClassInfo(response.data);
      } catch (error) {
        console.error("Error fetching class information:", error);
      }
    };
  
    if (selectedClass && isVisible) {
      fetchClassInfo();
    }
  }, [selectedClass, isVisible]);

  // Function to fetch subject name by ID
  const fetchSubjectCode = async (subjectId) => {
    try {
      const response = await axios.get(`http://mentorsattendance.site:5001/api/subject-code/${subjectId}`);
      return response.data.subjectCode;
    } catch (error) {
      console.error('Error fetching subject name:', error);
      return null;
    }
  };

  // Function to generate class code
  useEffect(() => {
    const generateClassCode = async () => {
      if (classInfo) {
        const { subject_id, day_of_week, start_time, end_time } = classInfo;
        const subjectCode = await fetchSubjectCode(subject_id);
        if (subjectCode) {
          const code = `${subjectCode}~${day_of_week.substring(0,3).toUpperCase()}~${start_time}~${end_time}`;
          setClassCode(code);
        }
      }
    };

    generateClassCode();
  }, [classInfo]);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const formatDate = (dateString) => {
    return dateString.substring(0, 10);
  };

  return (
    <div className="class-list-table">
      <div className="info-table-container">
        <h2 className="class-students-header">Class Students</h2>
        <div className="class-info">
          <a className='display-class-info' href="#" onClick={toggleVisibility}>Click to display class Information ...</a>
          {isVisible && classInfo && ( // Conditionally render class info based on visibility and data availability
            <div className='class-info-section'>
              <p className='class-info-values'>
                Class Code: <span className="badge"><span className="badge-text">{classCode}</span></span> | 
                Teacher: <span className="badge"><span className="badge-text">{classInfo.teacher}</span></span> | 
                Total Students: <span className="badge"><span className="badge-text">{students.length}</span></span> |
              </p>
              <button className='print-class-list-button' onClick={() => window.print()}>Print »</button>
            </div>
          )}
        </div>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <table className="custom-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Student ID</th>
                <th>Name</th>
                <th>Grade</th>
                <th>Email</th>
                <th>Date Registered</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student, index) => (
                <tr key={student.student_id}>
                  <td>{index + 1}</td>
                  <td>{student.student_id}</td>
                  <td>
                    <Link to={`/students/${encodeURIComponent(student.nickname)}`} className="student-link">
                      {student.nickname} 
                    </Link>
                  </td>
                  <td>{student.grade}</td>
                  <td>{student.email}</td>
                  <td>{formatDate(student.registration_date)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default InfoTable;


