import React, { useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import PlacesAutocomplete, { geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewStudentRegistration = () => {
  const [formData, setFormData] = useState({
    name: '',
    nickname: '',
    email: '',
    parentPhone: '',
    studentPhone: '',
    address: '',
    registrationDate: new Date(),
    monthlyPayment: '',
    grade: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date) => {
    console.log(date);
    setFormData({ ...formData, registrationDate: date });
  }

  const handleAddressChange = (address) => {
    setFormData({ ...formData, address});
  }

  const handlePlaceSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setFormData({ ...formData, address, latLng });
    } catch (error) {
      console.error('Error selecting place:', error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      await axios.post('http://mentorsattendance.site:5001/api/register-student', formData);
      toast.success('Student registered successfully');
      // Clear form fields
      setFormData({
        name: '',
        nickname: '',
        email: '',
        parentPhone: '',
        studentPhone: '',
        address: '',
        registrationDate: new Date(),
        monthlyPayment: '',
        grade: '',
      });
    } catch (error) {
      console.error('Error registering student:', error);
      toast.error('Failed to register student');
    }
  };

  return (
<div class="student-registration-area">
  <ToastContainer position="top-center"/>
  <h2 className='registration-header'>New Student Registration</h2>
  <form onSubmit={handleSubmit}>
    <div class="student-registration-form-group first-level">
      <div class="student-registration-form-label"> 
        <label for="name">Name</label>
        <input type="text" name="name" id="name" value={formData.name} onChange={handleChange} placeholder="First & Last Name" required />
      </div>
      <div class="student-registration-form-label">
        <label for="nickname">Nickname</label>
        <input type="text" name="nickname" id="nickname" value={formData.nickname} onChange={handleChange} placeholder="Nickname" required />
      </div>
      <div class="student-registration-form-label">
        <label for="email">Email</label>
        <input type="text" name="email" id="email" value={formData.email} onChange={handleChange} placeholder="Email" required />
      </div>
      <div class="student-registration-form-label">
        <label for="parentphone">Phone Guardian</label>
        <input type="text" name="parentPhone" id="parentphone" value={formData.parentPhone} onChange={handleChange} placeholder="416-555-0104" maxLength={16} required />
      </div>
      <div class="student-registration-form-label">
        <label for="studentphone">Phone Student</label>
        <input type="text" name="studentPhone" id="studentphone" value={formData.studentPhone} onChange={handleChange} placeholder="647-555-0104" maxLength={16} required />
      </div>
    </div>

    <div className="student-registration-form-group">
      <div className="student-registration-form-label">
        <label htmlFor="address">Address</label>
        <PlacesAutocomplete
          value={formData.address}
          onChange={handleAddressChange}
          onSelect={handlePlaceSelect}
          searchOptions={{ componentRestrictions: { country: 'ca' }}} // Restrict to Ontario, Canada
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Enter Address',
                  className: 'address-input',
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    </div>

    <div class="student-registration-form-group">
      <div class="student-registration-form-label">
        <label for="registrationDate">Date of Registration</label>
        <div className='calendar-container'>
          <DatePicker
            selected={formData.registrationDate}
            onChange={handleDateChange}
            showYearDropdown
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={15}
            scrollableYearDropdown
            required
          />
        </div>
      </div>
      <div class="student-registration-form-label">
        <label for="monthlypayment">Monthly Payment</label>
        <input type="text" name="monthlyPayment" id="monthlypayment" value={formData.monthlyPayment} onChange={handleChange} placeholder="400" required />
      </div>
      <div class="student-registration-form-label">
        <label for="grade">Grade</label>
        <select className='registration-select' name="grade" id="grade" value={formData.grade} onChange={handleChange} required>
          <option value="">Select Grade</option>
          {[...Array(12)].map((_, index) => (
            <option key={index + 1} value={index + 1}>
              {index + 1}
            </option>
          ))}
          <option value="UNI">University</option>
        </select>
      </div>
    </div>

    <button className='register-student-button' type="submit">Register Student</button>
  </form>
</div>


  );
};

export default NewStudentRegistration;

