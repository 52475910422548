import React, {useEffect} from 'react';

const MoveSection = ({ studentName, subject, subjects, setStudentName, setSubject, moveStudent, handleInputChange, searchResults, setSearchResults, isLoading, dropdownOpen, setDropdownOpen, handleSuggestionClick }) => {
  useEffect(() => {
    if (subjects.length > 0 && !subject) {
      setSubject(subjects[0]);
    }
  }, [subjects, subject, setSubject]);
  return (
    <div className='move-student-container'> 
      {/* Autocomplete for student name */}
      <h2 className="add-remove-header">Move Student</h2>
      <div className='input-container'>
        <div className="autocomplete-container">
          <input
            type="text"
            value={studentName}
            onChange={handleInputChange}
            placeholder="Enter Student Name"
          />
          {dropdownOpen && (
            <div className="autofill-dropdown">
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <ul>
                  {searchResults.map((student) => (
                    <li key={student.student_id} onClick={() => handleSuggestionClick(student.nickname)}>
                      {student.nickname}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
        {/* Dropdown for selecting subject */}
        {subjects.length > 0 && (
          <select
            className='registration-select'
            value={subject}
            onChange={(e) => {
              const selectedSubject = e.target.value;
              console.log('Selected Subject:', selectedSubject); // Log the selected subject
              setSubject(selectedSubject); // Set the state variable to the selected subject
            }}
          >
            <option value="">Select Subject</option>
            {subjects.map((subject, index) => (
              <option key={index} value={subject}>
                {subject}
              </option>
            ))}
          </select>
        )}
        
        {/* Button to move student */}
        <button className="add-remove-button" onClick={moveStudent}>Move Student</button>
      </div>
    </div>
  );
}

export default MoveSection;






