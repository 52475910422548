import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import axios from 'axios'; // Import axios
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditProfileForm = ({ initialValues, onClose }) => {
  const [formValues, setFormValues] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Check if the field name is 'name' or 'address'
    if (name === 'name' || name == 'nickname' || name === 'address') {
      // No trimming for 'name' and 'address'
      setFormValues({ ...formValues, [name]: value });
    } else {
      // Trim other field values before setting them to formValues
      setFormValues({ ...formValues, [name]: typeof value === 'string' ? value.trim() : value });
    }
  };
  

  const handleAddressChange = (address) => {
    setFormValues({ ...formValues, address });
  };

  const handlePlaceSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      console.log('Selected address:', results[0].formatted_address);
      console.log('LatLng:', latLng);
      setFormValues({ ...formValues, address: results[0].formatted_address });
    } catch (error) {
      console.error('Error selecting place:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Form Values:', formValues);
      // Send a PUT request to the backend API with the updated data
      await axios.put(`http://mentorsattendance.site:5001/api/update-student/${initialValues.student_id}`, formValues);
      toast.success('Profile updated successfully');
      console.log('Profile updated successfully');
      // onClose(); // Close the form after successfully updating the profile
    } catch (error) {
      toast.error('Error updating profile:', error);
      console.error('Error updating profile:', error);
    }
  };

  // Generate options for grades 1 to 12 and University
  const gradeOptions = Array.from({ length: 12 }, (_, index) => (
    <option key={index + 1} value={`${index + 1}`}>
      {index + 1}
    </option>
  ));
  gradeOptions.push(<option key="UNI" value="UNI">University</option>);

  return (
    <div className='edit-profile-overlay'>
        <ToastContainer position="top-left"/>
        <div className="edit-profile-container">
            <button className="close-button" onClick={onClose}>X</button>
            <form onSubmit={handleSubmit}>
            <h2 className='edit-header'>Edit Student Profile</h2>
            <div className="divider"></div>
            <div className="section-title">Personal Information</div>
                <div className='input-row'>
                    <label>
                    Full Name:
                    <input
                        type="text"
                        name="name"
                        value={formValues.name}
                        onChange={handleChange}
                        className='full-name-field' // Apply the class to the input element
                    />
                    </label>
                </div>
                <div className='input-row'>
                    <label>
                    Nickname:
                    <br></br>
                    <input
                        type="text"
                        name="nickname"
                        value={formValues.nickname}
                        onChange={handleChange}
                        className='nickname-field' // Apply the class to the input element
                    />
                    </label>
                </div>
                <div className='input-row'>
                    <label>
                    Email:
                    <input
                        type="email"
                        name="email"
                        value={formValues.email}
                        onChange={handleChange}
                        className='email-field' // Apply the class to the input element
                    />
                    </label>
                </div>
                <div className='input-row'>
                    <label>
                    Parent Phone:
                    <input
                        type="text"
                        name="parent_phone"
                        value={formValues.parent_phone}
                        onChange={handleChange}
                        maxLength={16}
                        className='parent-ph-field' // Apply the class to the input element
                    />
                    </label>
                </div>
                <div className='input-row'>
                    <label>
                    Student Phone:
                    <input
                        type="text"
                        name="student_phone"
                        value={formValues.student_phone}
                        onChange={handleChange}
                        maxLength={16}
                        className='student-ph-field' // Apply the class to the input element
                    />
                    </label>
                </div>
                <div className='input-row'>
                    <label>
                    Grade:
                    <select
                        name="grade"
                        value={formValues.grade}
                        onChange={handleChange}
                        className='registration-select grade-field' // Apply the class to the input element'
                    >
                        {gradeOptions}
                    </select>
                    </label>
                </div>
                <div className="input-row">
                    <label htmlFor="address">Address:</label>
                    <PlacesAutocomplete
                        value={formValues.address}
                        onChange={handleAddressChange}
                        onSelect={handlePlaceSelect}
                        searchOptions={{ componentRestrictions: { country: 'ca' }}} // Restrict to Canada
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <input
                            {...getInputProps({
                                placeholder: 'Enter Address',
                                className: 'address-input',
                            })}
                            />
                            <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                    className,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                                );
                            })}
                            </div>
                        </div>
                        )}
                    </PlacesAutocomplete>
                </div>
                <div className="divider"></div>
                <div className="section-title">Payment Settings</div>
                <div className='input-row'>
                    <label>
                    Monthly Bill:
                    <input
                        type="number"
                        name="monthly_payment"
                        value={formValues.monthly_payment}
                        onChange={handleChange}
                        className='monthly-bill-field' // Apply the class to the input element
                    />
                    </label>
                </div>
                {/* Add other input fields as needed */}
                <div className="button-container">
                    <button type="button" onClick={onClose}>Close</button>
                    <button className='save-profile' type="submit">Save Profile</button>
                </div>
            </form>
        </div>
    </div>
  );
};

export default EditProfileForm;
