// AttendanceSelectDropDown.jsx
import React from 'react';

const AttendanceDropDown = ({ selectedDate, renderDateOptions, renderClassTimeOptions, setSelectedDate, setStartTime, setEndTime, fetchStudents, setOpenButtonClicked }) => {
    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        // Convert selected date to UTC format
        console.log('Selected date:', selectedDate); // Add this log
        const date = new Date(selectedDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const localDate = new Date(utcDate.getTime() + (new Date().getTimezoneOffset() * 60000));
        const utcDateString = localDate.toISOString().slice(0, 10);
        console.log('Selected date:', utcDateString); // Add this log
        setSelectedDate(utcDateString);
        };

        const handleOpenButtonClick = () => {
            setOpenButtonClicked(true); // Set openButtonClicked to true when the "Open" button is clicked
            fetchStudents(); // Fetch students when the "Open" button is clicked
        };

    return (
        <div className="selection-container">
        <select className='registration-select' value={selectedDate} onChange={handleDateChange}>
            <option value="">Select Date</option>
            {renderDateOptions()}
        </select>
        <select className='registration-select' onChange={(e) => {
            const selectedClassTime = e.target.value.split(' - ');
            const start = selectedClassTime[0];
            const end = selectedClassTime[1];
            setStartTime(start);
            setEndTime(end);
            console.log('Start Time:', start);
            console.log('End Time:', end);
            console.log("Class Time", selectedClassTime);
        }}>
            <option value="">Select Time</option>
            {/* Map over classTimes to render options */}
            {renderClassTimeOptions()}
        </select>
        {/* Button to fetch attendance */}
        <button className='open-class-list-button' onClick={handleOpenButtonClick}>Open</button>
        </div>
    );
    }

export default AttendanceDropDown;
