import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Student Names are to be filtered by nicknames
const AddRemoveSection = ({ selectedClass, refreshTable, setRefreshTable }) => {
  const [studentName, setStudentName] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchStudents();
  }, [selectedClass, refreshTable]);

  const fetchStudents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("http://mentorsattendance.site:5001/api/active-students");
      setStudentList(response.data);
      setRefreshTable(false); // Reset refreshTable after fetching students
      console.log("Selected class ID inside fetchStudents:", selectedClass); // Log the selected class ID here
    } catch (error) {
      console.error("Error fetching students:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setStudentName(value);

  // Filter student list based on input value if it's not empty
  if (value.trim() !== "") {
    const filtered = studentList.filter(
      (student) =>
        student.nickname.toLowerCase().includes(value.toLowerCase()) ||
        (student.nickname && student.nickname.toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredStudents(filtered);
  } else {
    setFilteredStudents([]); // Clear the filtered student list if the input is empty
  }
  };

  const handleSuggestionClick = (name) => {
    // Set the selected student name and clear filtered students list
    setStudentName(name);
    setFilteredStudents([]);
  };

  const handleAddStudent = async () => {
    try {
        // Fetch list of students in the current class
        const response = await axios.get(`http://mentorsattendance.site:5001/api/class-students/${selectedClass}`);
        const studentsInClass = response.data;

        // Check if the student name already exists in the class
        const studentExists = studentsInClass.some(student => student.nickname === studentName);

        if (studentExists) {
            toast.error("Student already exists in the class");
            return;
        }
        // Send a request to add the student to the selected class
        await axios.post("http://mentorsattendance.site:5001/api/add-student-to-class", {
            classId: selectedClass,
            studentName: studentName
        });
        toast.success("Student added successfully to class:", selectedClass);
        setRefreshTable(true);
        setStudentName("");
    } catch (error) {
        toast.error("Error adding student to class:", error)
    }
  };

  const handleRemoveStudent = async () => {
    try{
        // Fetch list of students in the current class
        const response = await axios.get(`http://mentorsattendance.site:5001/api/class-students/${selectedClass}`);
        const studentsInClass = response.data;

        // Check if the student name already exists in the class
        const studentExists = studentsInClass.some(student => student.nickname === studentName);
        console.log(studentExists);
        if (!studentExists) {
            toast.error("Student does not exist in the class");
            return;
        }
        // Send a request to remove the student to the selected class
        await axios.post("http://mentorsattendance.site:5001/api/remove-student-from-class", {
            classId: selectedClass,
            studentName: studentName
        });
        toast.success("Student removed successfully from class:", selectedClass);
        setRefreshTable(true);
        setStudentName("");
    } catch (error) {
        toast.error("Error removing student from class:", error)
    }
  };

  return (
    <div className="autofill-dropdown add-remove-area">
      <ToastContainer position="top-center"/>
      <h2 className="add-remove-header">Add or Remove Student</h2>
      <input
        type="text"
        value={studentName}
        onChange={handleInputChange}
        placeholder="Enter student name..."
      />
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <ul>
          {filteredStudents.map((student) => (
            <li
              key={student.student_id}
              onClick={() => handleSuggestionClick(student.nickname)} // Set the selected student name when clicked
            >
              {student.nickname}
            </li>
          ))}
        </ul>
      )}
      <button className="add-remove-button" onClick={handleAddStudent}>Add Student</button>
      <button className="add-remove-button" onClick={handleRemoveStudent}>Remove Student</button>
    </div>
  );
};

export default AddRemoveSection;


