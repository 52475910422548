import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill'; // Import Quill
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmailForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const recipientEmailFromParam = queryParams.get('recipient') || '';

  const [recipientType, setRecipientType] = useState('individual');
  const [recipientEmail, setRecipientEmail] = useState(recipientEmailFromParam);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleRecipientChange = (e) => {
    setRecipientType(e.target.value);
    setRecipientEmail(''); // Reset recipient email when recipient type changes
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://mentorsattendance.site:5001/api/send-email', {
        recipientEmail: recipientType === 'individual' ? recipientEmail : [],
        recipientType: recipientType === 'individual' ? 'individual' : 'all',
        subject,
        message
      });
      toast.success("Email sent out");
    } catch (error) {
      toast.error('Error sending email:', error);
      console.error('Error sending email:', error);
    }
  };

  return (
    <div className="email-form-container">
      <ToastContainer position="top-center"/>
      <h2 className="class-list-header email-form-title">Email Form</h2>
      <form className="email-form" onSubmit={handleSubmit}>
        <label>
          Recipient Type:
          <select value={recipientType} onChange={handleRecipientChange}>
            <option value="individual">Individual</option>
            <option value="all">All Active Students</option>
          </select>
        </label>
        {recipientType === 'individual' && (
          <label>
            Recipient Email:
            <input className='email-field' type="email" value={recipientEmail} onChange={(e) => setRecipientEmail(e.target.value)} />
          </label>
        )}
        <label>
          Subject:
          <input className='email-field' type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
        </label>
        <label>
          Message:
          <ReactQuill 
            value={message} 
            onChange={(value) => setMessage(value)} 
            theme="snow" // Choose a theme
          />
        </label>
        <button type="submit">Send Email</button>
      </form>
    </div>
  );
};

export default EmailForm;



