import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../App.css'
import header from '../img/header.png'

const Login = ({ setIsLoggedIn, setUserRole }) => {
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://mentorsattendance.site:5001/api/login', { pin });
      if (response.data.success) {
        localStorage.setItem("userRole",response.data.role);
        localStorage.setItem("isLoggedIn",'true')
        setIsLoggedIn(true);
        setUserRole(response.data.role); // Set user role
        navigate('/main');
      }
    } catch (error) {
      setError('Incorrect pin. Please try again.');
    }
  };

  return (
    <div>
      <img className='logo' img src={header} alt={"header"}/>
      <div className='form-area'>
        <h1 className='sign-in'>Sign in as admin</h1>
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <form className='login-form' onSubmit={handleSubmit}>
          <label>
            <input
              type="password"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              maxLength="4"
              placeholder='Pin Number ####'
              required
            />
          </label>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
