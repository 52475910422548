import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import headerImage from "../img/header.png"
import robertSignature from "../img/robert-sig.png"
import logoImage from "../img/logo.png"
import envelopeImage from "../img/envelope.png"
import globeImage from "../img/globe.png"
import phoneImage from "../img/phone.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PaymentsOverlay = ({ studentId, studentName, onClose }) => {
  const [monthlyBill, setMonthlyBill] = useState(0);
  const [currentBill, setCurrentBill] = useState(0);
  const [lastPayment, setLastPayment] = useState(null);
  const [amountToPay, setAmountToPay] = useState('');
  const [showPaymentHistory, setShowPaymentHistory] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [newBalance, setNewBalance] = useState('');
  const [studentInfo, setStudentInfo] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false); // State to track if images are loaded
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const imagePath = headerImage;
  const sigPath = robertSignature;
  const logoPath = logoImage;
  const envelopePath = envelopeImage;
  const globePath = globeImage;
  const phonePath = phoneImage;

  // Fetch student's monthly bill and current bill
  const fetchMonthlyBillAndCurrentBill = async () => {
    try {
    const response = await axios.get(`http://mentorsattendance.site:5001/api/get-student-info/${studentId}`);
    setMonthlyBill(response.data.studentInfo.monthly_payment);
    setCurrentBill(response.data.studentInfo.balance);
    } catch (error) {
    console.error('Error fetching student bills:', error);
    }
  };

  useEffect(() => {
    const fetchStudentInfo = async () => {
      try {
        const response = await axios.get(`http://mentorsattendance.site:5001/api/get-student-info/${studentId}`);
        setStudentInfo(response.data.studentInfo);
      } catch (error) {
        console.error('Error fetching student info:', error);
      }
    };

    fetchStudentInfo();
  }, []);

  // Function to fetch last payment and payment history
  const fetchLastPaymentandHistory = async () => {
    try {
    const response = await axios.get(`http://mentorsattendance.site:5001/api/student-payment-history/${studentId}`);
    const paymentHistory = response.data.paymentHistory;

    // Sort paymentHistory array by payment_date in descending order
    paymentHistory.sort((a, b) => new Date(b.payment_date) - new Date(a.payment_date));

    // Get the latest payment (first element after sorting)
    const latestPayment = paymentHistory[0];

    // Extract only payment amount and date for payment history
    const simplifiedPaymentHistory = paymentHistory.map(payment => ({
        payment_amount: payment.payment_amount,
        payment_date: payment.payment_date
    }));

    // Set the last payment and payment history in state
    setLastPayment(latestPayment);
    setPaymentHistory(simplifiedPaymentHistory);
    } catch (error) {
    console.error('Error fetching last payment:', error);
    }
};

  // Function to filter payment history based on selected dates
  const filterPaymentHistory = () => {
    if (fromDate && toDate) {
      return paymentHistory.filter(payment => {
        const paymentDateStr = payment.payment_date.slice(0, 10); // Extract YYYY-MM-DD from payment_date
        const isInRange = paymentDateStr >= fromDate && paymentDateStr <= toDate;
        return isInRange;
      });
    } else {
      return paymentHistory;
    }
  };

  useEffect(() => {
    fetchMonthlyBillAndCurrentBill();
    fetchLastPaymentandHistory();
  }, [studentId]);

  // Function to handle payment submission
  const handleSubmitPayment = async () => {
    // Validate amount to pay
    if (!amountToPay || isNaN(amountToPay) || parseFloat(amountToPay) <= 0) {
      toast.error('Please enter a valid amount to pay.');
      return;
    }

    try {
      await axios.post('http://mentorsattendance.site:5001/api/process-individual-payment', {
        studentId,
        amount: parseFloat(amountToPay)
      });
      toast.success('Payment successful!');
      // Refresh data after successful payment
      fetchMonthlyBillAndCurrentBill();
      fetchLastPaymentandHistory();
      setAmountToPay('');
    } catch (error) {
      console.error('Error processing payment:', error);
      toast.error('Failed to process payment. Please try again later.');
    }
  };

  // Function to handle updating the balance manually
  const handleUpdateBalance = async () => {
    if (!newBalance || isNaN(newBalance)) {
      toast.error('Please enter a valid balance.');
      return;
    }

    try {
      await axios.post('http://mentorsattendance.site:5001/api/update-balance', {
        studentId,
        balance: parseFloat(newBalance)
      });
      toast.success('Balance updated successfully!');
      // Refresh data after successful update
      fetchMonthlyBillAndCurrentBill();
      setNewBalance('');
    } catch (error) {
      console.error('Error updating balance:', error);
      toast.error('Failed to update balance. Please try again later.');
    }
  };

  // Function to preload images
  const preloadImages = () => {
    const img1 = new Image();
    const img2 = new Image();
    const img3 = new Image();
    const img4 = new Image();
    const img5 = new Image();
    const img6 = new Image();
  
    img1.onload = img2.onload = img3.onload = img4.onload= img5.onload= img6.onload = () => {
      setImageLoaded(true);
    };
  
    img1.src = headerImage;
    img2.src = robertSignature;
    img3.src = logoImage;
    img4.src = envelopeImage;
    img5.src = globeImage;
    img6.src = phoneImage;
  };
  

  useEffect(() => {
    preloadImages();
  }, []);

  // Function to generate HTML content for printing
  const printTableContent = () => {
    // Check if the images are loaded
    if (imageLoaded) {
      console.log('Images are loaded, proceeding with printing...');
      // If the images are loaded, proceed with printing
      console.log('Filtered Payment History:', filterPaymentHistory());
      openPrintWindow(studentName, filterPaymentHistory(), logoPath, fromDate, toDate);
    } else {
      // If the images are not loaded, wait for them to load
      console.log('Images not loaded yet');
    }
  };

  const openPrintWindow = (studentName, paymentHistory, logoPath, fromDate, toDate) => {
    // Create a new window
    if (imageLoaded) {
      // If the images are loaded, proceed with printing
      const printWindow = window.open('', '_blank');
      // Format fromDate and toDate
      // Check if fromDate and toDate are specified
      let fromDateFormatted, toDateFormatted;

      if (fromDate && toDate) {
        fromDateFormatted = new Date(fromDate).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        toDateFormatted = new Date(toDate).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
      } else {
        // Use registrationDate from studentInfo for fromDate
        fromDateFormatted = new Date(studentInfo.registration_date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        // Use current date for toDate
        toDateFormatted = new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
      }
      console.log("Formatted from Date:", fromDateFormatted);
      console.log("Formatted to Date:", toDateFormatted)
  
    // Create HTML content
    const htmlContent = `
      <html>
        <head>
          <title>Payment History</title>
          <style>
            body {
              font-family: Arial, sans-serif;
            }
            .header {
              margin-top: 10px;
              display: flex;
              align-items: center; /* Vertically center items */
              justify-content: center; /* Horizontally center items */
              flex-wrap: wrap; /* Allow items to wrap if they overflow */
            }
            .logo {
              margin-right: 20px;
            }
            .logo img {
              max-width: 150px; /* Adjust size as needed */
              height: 150px;
            }
            .mentors-header-info {
              margin-right: 20px;
            }
            .mentors-info {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              margin-bottom: 10px;
              margin-top: 10px;
            }
            .mentors-info p {
              margin-right: 20px;
            }    
            .payments-container {
              padding: 10px;
            }
            .payments-container h2 {
              text-align: center; /* Center the content */
            }
            .payment-history-table-container {
              width: 100%;
              display: flex;
              justify-content: center;
            }
            .payment-history-table {
              width: 80%;
              border-collapse: collapse;
            }
            .payment-history-table th,
            .payment-history-table td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }
            .payment-history-table th {
              background-color: #f2f2f2;
            }
            .dates {
              display: flex;
              justify-content: center;
              gap: 10%;
            }
            .mentors-header {
              font-size: 50px;
            }
            .address{
              margin-top: 10px;
            }
            .icons{
              height: 16px;
              width: 16px;
            }
            .icon-text-wrapper {
              display: flex;
              align-items: center; /* Align items vertically */
              gap: 5px;
            }
          </style>
        </head>
        <body>
          <div class="header">
            <div class="logo">
              <img src="${logoPath}" alt="Banner">
            </div>
            <div class="mentors-header-info">
              <h1 class="mentors-header">Mentor's Academy</h1>
              <p class="address">115 Lebovic Avenue, Unit: 3 & 4, Scarborough ON, M1L 4T7</p>
            </div>
          </div>
          <div class="mentors-info">
            <p><img class="icons" src="${globePath}" alt="Globe Icon" /> www.mentorsacademy.ca</p>
            <div class="icon-text-wrapper">
              <img class="icons" src="${envelopePath}" alt="Envelope Icon" />
              <p>contact@mentorsacademy.ca</p>
            </div>
            <p><img class="icons" src="${phonePath}" alt="Phone Icon" /> 647-780-8811</p>
          </div>
          <div class="payments-container">
            <h2>${studentName} Payment History</h2>
            <div class="dates">
              <p>From: ${fromDateFormatted}</p>
              <p>To: ${toDateFormatted}</p>
            </div>
            <div class="payment-history-table-container">
              <table class="payment-history-table">
                <thead>
                  <tr>
                    <th>Payment Received Date</th>
                    <th>Amount Paid</th>
                  </tr>
                </thead>
                <tbody>
                  ${paymentHistory.map(payment => `
                    <tr>
                      <td>${payment.payment_date.substring(0,10)}</td>
                      <td>$${payment.payment_amount}</td>
                    </tr>
                  `).join('')}
                  <tr>
                    <td><strong>Total</strong></td>
                    <td><strong>$${calculateTotalPayment(paymentHistory)}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </body>
      </html>
    `;
  
    // Write HTML content to the new window
    printWindow.document.write(htmlContent);
  
    // Print the new window
    printWindow.print();

  } else {
    // If the images are not loaded, wait for them to load
    console.log('Images not loaded yet');
  }
};

  const calculateTotalPayment = (paymentHistory) => {
    let total = 0;
    paymentHistory.forEach(payment => {
      total += parseFloat(payment.payment_amount);
    });
    return total.toFixed(2); // Format total amount to two decimal places
  };

  const generateConfirmationNumber = () => {
    // Get current timestamp in milliseconds
    const timestamp = Date.now();
    // Generate a random number between 1000 and 9999
    const randomNum = Math.floor(Math.random() * 9000) + 10000;
    // Combine timestamp and random number to create a unique confirmation number
    const confirmationNumber = parseInt(timestamp.toString() + randomNum.toString());
    return confirmationNumber;
  };


  const printConfirmation = (payment, studentName, studentInfo) => {
    // Generate unique confirmation number
    const confirmationNumber = generateConfirmationNumber();
    const paymentDate = new Date(payment.payment_date);
    const month = paymentDate.toLocaleDateString('en-US', { month: 'long' });
    // Data object containing the variables to be filled in the template
    const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Payment Confirmation</title>
      <style>
        /* Add your CSS styles here */
        body {
          font-family: Arial, sans-serif;
        }
        .header {
          margin-top: 10px;
          display: flex;
          align-items: center; /* Vertically center items */
          justify-content: center; /* Horizontally center items */
          flex-wrap: wrap; /* Allow items to wrap if they overflow */
        }
        .logo {
          margin-right: 20px;
        }
        .logo img {
          max-width: 150px; /* Adjust size as needed */
          height: 150px;
        }
        .mentors-header-info {
          margin-right: 20px;
        }
        .mentors-info {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-bottom: 10px;
          margin-top: 10px;
        }
        .mentors-info p {
          margin-right: 20px;
        }
        .container {
          width: 100%;
          margin: auto;
          font-family: Arial, sans-serif;
          text-align: center;
        }
        .payment-header {
          text-align: right; /* Center the content */
          margin-top: 20px; /* Adjust margin as needed */
          margin-right: 10%;
        }
        .mentors-header {
          font-size: 50px; /* Adjust font size as needed */
        }
        .address {
          margin-top: 10px;
          margin-right: 10px;
        }
        .icons {
          height: 16px;
          width: 16px;
        }
        .icon-text-wrapper {
          display: flex;
          align-items: center; /* Align items vertically */
          gap: 5px;
        }
        .student-info {
          text-align: left;
          margin-left: 10%;
          margin-bottom: 5%;
        }
        .letter {
          text-align: left;
          margin-left: 10%;
          width: 80%;
        }
        .automated {
          margin-left: 5%;
        }
      </style>
      </head>
      <body>
      <div class="container">
        <!-- Mentors Logo -->
        <div class="header">
          <div class="logo">
            <img src="${logoPath}" alt="Banner">
          </div>
          <div class="mentors-header-info">
            <h1 class="mentors-header">Mentor's Academy</h1>
            <p class="address">115 Lebovic Avenue, Unit: 3 & 4, Scarborough ON, M1L 4T7</p>
          </div>
        </div>
      <div class="mentors-info">
        <p><img class="icons" src="${globeImage}" alt="Globe Icon" /> www.mentorsacademy.ca</p>
        <div class="icon-text-wrapper">
          <img class="icons" src="${envelopeImage}" alt="Envelope Icon" />
          <p>contact@mentorsacademy.ca</p>
        </div>
        <p><img class="icons" src="${phoneImage}" alt="Phone Icon" /> 647-780-8811</p>
      </div>
        <!-- Payment Confirmation Number and Date -->
        <div class="payment-header">
          <p>Payment Confirmation #: ${confirmationNumber} </p>
          <p>Date: ${payment.payment_date.substring(0,10)} </p>
        </div>
        <div class="parent-info">
          <!-- Student's Information -->
          <div class="student-info">
              <p>${studentName}</p>
              <p>${studentInfo.email}</p>
              <p>${studentInfo.address}</p>
          </div>
        </div>
        <!-- Clear float after student information -->
        <div style="clear:both;"></div>
        <!-- Letter -->
        <div class="letter">
          <p>Hello ${studentName},</p>
          <p>
              I hope you are doing well! We received a payment of $${payment.payment_amount} from you. The payment has been processed as of ${payment.payment_date.substring(0,10)} for the month of ${month}, 
              setting your outstanding balance to $${studentInfo.balance}.
          </p>
          <p>If you have any questions regarding this statement, please contact us by call/text at +1 (647) 780-8811 or email us at contact@mentorsacademy.ca as soon as possible.</p>
          <p>We look forward to hearing from you. Thanks for your time and cooperation in building your bright future.</p>
        </div>
        <!-- Letter Ending -->
        <div class="letter">
          <p>Sincerely,</p>
          <img src="${sigPath}" alt="Robert Signature" style="width: 150px; height: auto;">
          <p>J. Robbert</p>
          <p>Coordinator</p>
          <p>Account's Division, Mentor's Academy</p>
          <p>+1 (647) 780-8811</p>
        </div>
        <div class="automated">
          <b>This is an automated payment confirmation generated by Mentors Academy software.</b>
        </div>
      </div>
      <!-- Rest of the content -->
      </body>
      </html>    
    `;

      
    // Print the generated HTML content
    printHTML(htmlContent);
  
  };

  const printHTML = (htmlContent) => {
    // Create a new window
    const printWindow = window.open('', '_blank');

    // Write HTML content to the new window
    printWindow.document.write(htmlContent);

    // Print the new window
    printWindow.print();
  };
  
  return (
    <div className="payments-overlay">
       <ToastContainer position="top-center"/>
        <div className='payments-container'>
        <button className="close-payment-button" onClick={onClose}>X</button>
        <h2>Payments</h2>
        <div>
            <p>Monthly Bill: ${monthlyBill}</p>
            <p>Current Bill (Amount Due): ${currentBill}</p>
        </div>
        {lastPayment && (
            <div>
            <p>Last Payment:</p>
            <p>Date: {lastPayment.payment_date.substring(0,10)}</p>
            <p>Amount Paid: ${lastPayment.payment_amount}</p>
            </div>
        )}
        <div className="payment-input-row">
            <input
            type="number"
            value={amountToPay}
            onChange={(e) => setAmountToPay(e.target.value)}
            placeholder="Enter amount to pay"
            />
            <button onClick={handleSubmitPayment}>Pay</button>
        </div>
        <div className="payment-input-row">
            <input
            type="number"
            value={newBalance}
            onChange={(e) => setNewBalance(e.target.value)}
            placeholder="Enter new balance"
            />
            <button onClick={handleUpdateBalance}>Set</button>
        </div>
        <div>
            <label>
              From: 
            </label>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              placeholder="From Date"
            />
            <label>
              To: 
            </label>
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              placeholder="To Date"
            />
            <br></br>
            <br></br>
            <button onClick={() => setShowPaymentHistory(!showPaymentHistory)}>
            {showPaymentHistory ? 'Hide Payment History' : 'Show Payment History'}
            </button>
            {showPaymentHistory && (
            <div className="payment-history-table-container">
              <table className="payment-history-table">
                  <thead>
                  <tr>
                      <th>Date</th>
                      <th>Amount Paid</th>
                      <th>Confirmation</th>
                  </tr>
                  </thead>
                  <tbody>
                  {fromDate && toDate ? filterPaymentHistory().map((payment, index) => (
                    <tr key={index}>
                      <td>{payment.payment_date.substring(0, 10)}</td>
                      <td>${payment.payment_amount}</td>
                      <td><button onClick={() => printConfirmation(payment, studentName, studentInfo)}>Print</button></td>
                    </tr>
                  )) : paymentHistory.map((payment, index) => (
                    <tr key={index}>
                      <td>{payment.payment_date.substring(0, 10)}</td>
                      <td>${payment.payment_amount}</td>
                      <td><button onClick={() => printConfirmation(payment, studentName, studentInfo)}>Print</button></td>
                    </tr>
                  ))}
                  </tbody>
              </table>
            </div>
            )}
        </div>
        <div className="payment-buttons">
          <button onClick={onClose}>Close</button>
          <button onClick={() => printTableContent()}>Print Payment History</button>
        </div>
        </div>
    </div>
  );
};

export default PaymentsOverlay;
