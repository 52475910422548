import React, { useState, useEffect } from 'react';
import ClassDropdown from './ClassDropdown';
import InfoTable from './InfoTable';
import AddRemoveSection from './AddRemoveSection';
import axios from 'axios';
import Header from './Header';

const ClassList = () => {
  const [showTable, setShowTable] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null); // State to store selected class
  const [selectedSubject, setSelectedSubject] = useState(null); // State to store selected subject
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(null); // State to store selected day of week
  const [selectedTime, setSelectedTime] = useState(null); // State to store selected time
  const [refreshTable, setRefreshTable] = useState(false); // State to trigger table refresh

  const handleShowTable = () => {
    setShowTable(true);
    setRefreshTable(true);
  }

  // Function to handle class selection
  const handleClassSelection = async (classData) => {
    setSelectedSubject(classData.subject_id);
    setSelectedClass(null); // Reset selectedClass to null initially
  }

  // Call API to fetch class ID whenever subject, day of week, or time changes
  useEffect(() => {
    const fetchClassId = async () => {
      try {
        console.log("Subject: " + selectedSubject + " Day of Week: " + selectedDayOfWeek + " Time: " + selectedTime);
        if (selectedSubject && selectedDayOfWeek && selectedTime) {
          let startTime, endTime
          if (selectedTime) {
            [startTime, endTime] = selectedTime.split('-');
          }
          const response = await axios.get('http://mentorsattendance.site:5001/api/classId', {
            params: {
              subject: selectedSubject,
              dayOfWeek: selectedDayOfWeek,
              startTime: startTime,
              endTime: endTime
            }
          });
          console.log(response.data.classId)
          setSelectedClass(response.data.classId);
        }
      } catch (error) {
        console.error('Error fetching class ID:', error);
      }
    };

    fetchClassId();
  }, [selectedSubject, selectedDayOfWeek, selectedTime]);

  // Log selected class whenever it changes
  useEffect(() => {
    console.log("Selected class:", selectedClass);
  }, [selectedClass]);

  return (
    <div>
      <div>
        <Header></Header>
      </div>
      <div className='class-list-header-container'>
        <h1 className='class-list-header'>Class List</h1>
      </div>
      <div className='class-select-section'>
        <ClassDropdown
          selectedSubject={selectedSubject}
          setSelectedSubject={setSelectedSubject} 
          onSelectClass={handleClassSelection} 
          onSelectDayOfWeek={setSelectedDayOfWeek} 
          onSelectTime={setSelectedTime} 
        /> {/* Pass onSelectClass prop to handle class selection */}
        <button className='open-class-list-button' onClick={handleShowTable}>Open</button>
      </div>
      {showTable && (
        <>
          <div style={{ marginTop: '-15px' }}> {/* Apply a negative margin to move AddRemoveSection up */}
          <InfoTable 
            selectedClass={selectedClass} 
            selectedSubject={selectedSubject} 
            selectedDayOfWeek={selectedDayOfWeek} 
            selectedTime={selectedTime} 
            refreshTable={refreshTable} 
            setRefreshTable={setRefreshTable} 
          /> {/* Pass selectedClass prop to InfoTable */}
          </div>
          <div style={{ marginTop: '-15px' }}> {/* Apply a negative margin to move AddRemoveSection up */}
          <AddRemoveSection 
            selectedClass={selectedClass} 
            refreshTable={refreshTable} 
            setRefreshTable={setRefreshTable} 
          /> {/* Pass selectedClass prop to AddRemoveSection */}
          </div>
        </>
      )}
    </div>
  );
}

export default ClassList;





