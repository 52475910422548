import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import StudentInfoBox from './StudentInfoBox'; 
import axios from 'axios';

const StudentPage = () => {
  const { nickname } = useParams(); // Extract the nickname from the URL params
  const [studentId, setStudentId] = useState(null);
  const [studentAddress, setStudentAddress] = useState('');
  const [studentCoordinates, setStudentCoordinates] = useState(null);
  const [classList, setClassList] = useState([]);
  const [presentClasses, setPresentClasses] = useState([]);
  const [absentClasses, setAbsentClasses] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [presentMonth, setPresentMonth] = useState('');
  const [absentMonth, setAbsentMonth] = useState('');
  const [isActive, setIsActive] = useState(false); // Define the active state in the parent component

  useEffect(() => {
    const fetchStudentId = async () => {
      try {
        const response = await axios.get(`http://mentorsattendance.site:5001/api/student-id/${nickname}`);
        setStudentId(response.data.studentId);
      } catch (error) {
        console.log('Error fetching student id', error);
      }
    };
    fetchStudentId();
  }, [nickname]);

  useEffect(() => {
    const fetchStudentInfo = async () => {
      try {
        if(studentId){
          const response = await axios.get(`http://mentorsattendance.site:5001/api/get-student-info/${studentId}`);
          setStudentAddress(response.data.studentInfo.address);
        }
      } catch (error) {
        console.error('Error fetching student information:', error);
      }
    };
    fetchStudentInfo();
  }, [studentId]);

  useEffect(() => {
    const fetchCoordinates = async () => {
      try {
        if (studentAddress) {
          const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
            params: {
              address: studentAddress,
              key: 'AIzaSyAj8PN9a_kz-SpXl82gs6nq1cYRxBmMtFA',
            },
          });
          const location = response.data.results[0].geometry.location;
          setStudentCoordinates(location);
        }
      } catch (error) {
        console.error('Error fetching coordinates:', error);
      }
    };

    fetchCoordinates();
  }, [studentAddress]);

  console.log('Student Coordinates:', studentCoordinates);

  useEffect(() => {
    if (studentCoordinates) {
      // Load the Google Maps API script
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAj8PN9a_kz-SpXl82gs6nq1cYRxBmMtFA&callback=initMap`;
      script.async = true;
      script.defer = true;
      script.onload = initMap;
      document.head.appendChild(script);
    }
  }, [studentCoordinates]);

  useEffect(() => {
    const fetchClassList = async () => {
      try {
        if (studentId) {
          const response = await axios.get(`http://mentorsattendance.site:5001/api/student-classlist/${studentId}`);
          setClassList(response.data.classes);
        }
      } catch (error) {
        console.error('Error fetching class list:', error);
      }
    };

    fetchClassList();
  }, [studentId, isActive]);

  useEffect(() => {
    const fetchPresentClasses = async () => {
      try {
        if (studentId) {
          const response = await axios.get(`http://mentorsattendance.site:5001/api/student-attendance/${studentId}`, {
            params: {
              year: selectedYear || new Date().getFullYear(),
              month: presentMonth || new Date().getMonth() + 1,
              status: 'P', // For present classes
            },
          });
          setPresentClasses(response.data.attendanceData);
        }
      } catch (error) {
        console.error('Error fetching present classes:', error);
      }
    };

    fetchPresentClasses();
  }, [studentId, selectedYear, presentMonth]);

  useEffect(() => {
    const fetchAbsentClasses = async () => {
      try {
        if (studentId) {
          const response = await axios.get(`http://mentorsattendance.site:5001/api/student-attendance/${studentId}`, {
            params: {
              year: selectedYear || new Date().getFullYear(),
              month: absentMonth || new Date().getMonth() + 1,
              status: 'A', // For absent classes
            },
          });
          setAbsentClasses(response.data.attendanceData);
        }
      } catch (error) {
        console.error('Error fetching absent classes:', error);
      }
    };

    fetchAbsentClasses();
  }, [studentId, selectedYear, absentMonth]);

  const initMap = () => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: studentCoordinates,
      zoom: 15,
    });

    new window.google.maps.Marker({
      position: studentCoordinates,
      map: map,
      title: 'Student Location',
    });
  };

  const handlePresentMonthChange = (event) => {
    setPresentMonth(event.target.value);
  };

  const handleAbsentMonthChange = (event) => {
    setAbsentMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  // Function to populate options for month and year select dropdowns
  const populateMonthOptions = () => {
    const months = [
      { value: '01', label: 'January' },
      { value: '02', label: 'February' },
      { value: '03', label: 'March' },
      { value: '04', label: 'April' },
      { value: '05', label: 'May' },
      { value: '06', label: 'June' },
      { value: '07', label: 'July' },
      { value: '08', label: 'August' },
      { value: '09', label: 'September' },
      { value: '10', label: 'October' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' },
    ];
    return months.map(month => (
      <option key={month.value} value={month.value}>{month.label}</option>
    ));
  };

  const populateYearOptions = () => {
    // Replace this with your logic to get the year range
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2022; year--) {
      years.push(year);
    }
    return years.map(year => (
      <option key={year} value={year}>{year}</option>
    ));
  };


  return (
    <div className="student-page">
      <div className='student-page-top-half'>
        {studentId && <StudentInfoBox studentId={studentId} isActive={isActive} setIsActive={setIsActive} />}
        {/* Map section */}
        <div id="map"></div>
      </div>
      <div className='student-page-bottom-half'>
        {/* Class Management header */}
        <div className='class-management-section'>
          <h2 className="class-management-header">Class Management</h2>
          <div className="year-select">
            <label htmlFor="year"></label>
            <select className='registration-select' id="year" onChange={handleYearChange} value={selectedYear}>
              {!selectedYear && <option value="">Select Year</option>}
              {populateYearOptions()}
            </select>
          </div>
        </div>
        {/* Faint border */}
        <div className="faint-border"></div>
        {/* Current, Present, and Absent Classes sections */}
        <div className="current-present-absent-container">
        <div className='class-list-section'>
          <div className='class-section-header'>
            <h2 className="class-title">Current Classes</h2> 
            <span className="class-num-badge">{classList.length}</span>
          </div>
          <table className="custom-table">
            <thead>
              <tr>
                <th>Class Code</th>
                <th>Teacher</th>
              </tr>
            </thead>
            <tbody>
              {classList.map((classInfo, index) => (
                <tr key={index}>
                  <td>{classInfo.ClassCode}</td>
                  <td>{classInfo.Teacher}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
         {/* Present Classes section */}
         <div className='class-list-section'>
          <div className='class-section-header'>
            <h2>Present Classes</h2>
            <div className="month-select">
              <label htmlFor="present-month"></label>
              <select className='registration-select' id="present-month" onChange={handlePresentMonthChange} value={presentMonth}>
                {!presentMonth && <option value="">Select Month</option>}
                {populateMonthOptions()}
              </select>
          </div>
        </div>
          <table className="custom-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Class Code</th>
              </tr>
            </thead>
            <tbody>
              {presentClasses.map((classInfo, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{classInfo.date.substring(0,10)}</td>
                  <td>{classInfo.classCode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
         {/* Absent Classes section */}
         <div className='class-list-section'>
         <div className='class-section-header'>
          <h2>Absent Classes</h2>
          <div className="month-select">
            <label htmlFor="present-month"></label>
            <select className='registration-select' id="present-month" onChange={handleAbsentMonthChange} value={absentMonth}>
              {!absentMonth && <option value="">Select Month</option>}
              {populateMonthOptions()}
            </select>
          </div>
          </div>
          <table className="custom-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Class Code</th>
              </tr>
            </thead>
            <tbody>
              {absentClasses.map((classInfo, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{classInfo.date.substring(0,10)}</td>
                  <td>{classInfo.classCode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </div>
      </div>
    </div>
  );
};

export default StudentPage;

