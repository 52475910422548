import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../img/logo.png';
import axios from 'axios';

const MainNavbar = ({ isLoggedIn, userRole, handleLogout }) => {
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const inputRef = useRef(null);
  const location = useLocation(); // Get current location using useLocation hook
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 430);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 430);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial value

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const fetchSearchResults = async (value) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`http://mentorsattendance.site:5001/api/students`);
      // Filter search results based on input value
      const filteredResults = response.data.filter(student =>
        student.nickname.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(filteredResults);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.trim(); // Trim whitespace
    setSearchInput(value);

    // Fetch search results only if input value is not empty
    if (value !== '' && value.length > 0) {
      fetchSearchResults(value);
      setDropdownOpen(true); // Open the dropdown
    } else {
      setSearchResults([]); // Clear search results if input value is empty
      setDropdownOpen(false); // Close the dropdown
    }
  };

  const handleInputClick = () => {
    setSearchInput('');
    setDropdownOpen(true); // Open the dropdown
    inputRef.current.focus(); // Set focus back to the input field after clearing
  };

  const handleSearchClick = () => {
    setSearchInput('');
    setDropdownOpen(false); // Close the dropdown when search button is clicked
  };

  const handleSuggestionClick = (name) => {
    setSearchInput(name);
    inputRef.current.focus(); // Set focus back to the input field after selecting suggestion
    setDropdownOpen(false); // Close the dropdown
  };

  return (
    <div className="navbar">
      <div className="navbar-menu">
        <div className="navbar-logo">
          <img src={logo} alt="logo" />
          <span>
            {isMobile ? 'Mentors Attendance' : 'MA-Attendance'}
          </span>
        </div>
        <div className="navbar-item">
          <Link to="/main" className={location.pathname === '/main' ? 'navbar-link active' : 'navbar-link'}>Main</Link>
        </div>
        <div className="navbar-item">
          <Link to="/list" className={location.pathname === '/list' ? 'navbar-link active' : 'navbar-link'}>
            {isMobile ? 'List' : 'List - Classes/Students'}
          </Link>
        </div>
        <div className="navbar-item dropdown">
          <span className="navbar-link">
            {isMobile ? 'Control' : 'Control Panel'}
          </span>
          <div className="dropdown-content">
            {userRole === 'admin' && <Link to="/payments" className="navbar-link">Payments</Link>}
            <Link to="/emails" className="navbar-link">Emails</Link>
            <Link to="/registration" className="navbar-link">Registration</Link>
            {userRole === 'admin' && <Link to="/active-students" className="navbar-link">Active students</Link>}
            <Link to="/" className="navbar-link" onClick={handleLogout}>Logout</Link>
          </div>
        </div>
      </div>
      <div className="navbar-search">
        <input
          ref={inputRef}
          type="text"
          placeholder="Search Students"
          value={searchInput}
          onChange={handleInputChange}
          onClick={handleInputClick} // Clear input when clicked
        />
        <Link to={`/students/${encodeURIComponent(searchInput)}`} className="search-button" onClick={handleSearchClick}>
          Search
        </Link>
        {dropdownOpen && (
          <div className="dropdown-menu">
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <ul>
                {searchResults.map((student) => (
                  <li key={student.student_id} onClick={() => handleSuggestionClick(student.nickname)}>
                    {student.nickname}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MainNavbar;































