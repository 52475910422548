import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewClassRegistration = () => {
  const [formData, setFormData] = useState({
    subjectName: '', // Change to subjectName
    dayOfWeek: '',
    startTime: '',
    endTime: '',
    teacher: ''
  });

  const [subjects, setSubjects] = useState([]);
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  useEffect(() => {
    //Fetch subjects data from the backend
    const fetchSubjects = async () => {
      try {
        const response = await axios.get('http://mentorsattendance.site:5001/api/subjects');
        setSubjects(response.data);
      } catch (error) {
        console.error('Error fetching subjects:', error);
      }
    };

    fetchSubjects();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      console.log(formData);
      await axios.post('http://mentorsattendance.site:5001/api/register-class', formData);
      toast.success('Class registered successfully');
      setFormData({
        subjectName: '',
        dayOfWeek: '',
        startTime: '',
        endTime: '',
        teacher: '',
      })
    } catch (error) {
      console.error('Error registering class:', error);
      toast.error('Failed to register class:', error.message);
    }

  };

  return (
    <div className='class-registration-area'>
      <ToastContainer position="top-center"/>
      <h2 className='registration-header'>New Class Registration</h2>
      <form onSubmit={handleSubmit} className="class-registration-form">
        <div className="form-group">
          <label className="class-registration-form-label">Subject</label>
          <select
            name="subjectName" // Change to subjectName
            className='registration-select'
            value={formData.subjectName} // Change to subjectName
            onChange={handleChange}
            required
          >
            <option value="">Select subject...</option>
            {subjects.map((subject, index) => (
              <option key={index} value={subject}>{subject}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label className="class-registration-form-label">Day of the Week</label>
          <select
            name="dayOfWeek"
            className='registration-select'
            value={formData.dayOfWeek}
            onChange={handleChange}
            required
          >
            <option value="">Select day...</option>
            {daysOfWeek.map((day, index) => (
              <option key={index} value={day}>{day}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label className="class-registration-form-label" id='time-label'>Start - End Time:</label>
          <div className="time-inputs">
            <input
              type="text"
              name="startTime"
              value={formData.startTime}
              onChange={handleChange}
              required
              placeholder="Start"
            />
            <input
              type="text"
              name="endTime"
              value={formData.endTime}
              onChange={handleChange}
              required
              placeholder="End"
            />
          </div>
        </div>

        <div className="form-group">
          <label className="class-registration-form-label">Teacher</label>
          <input
            type="text"
            name="teacher"
            value={formData.teacher}
            onChange={handleChange}
            required
            placeholder='Teacher Name'
          />
        </div>

        <button type="submit" className="register-class-button">Register Class</button>
      </form>
    </div>
  );
};

export default NewClassRegistration;
